import React, { useEffect } from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO_2, NAVIGATION_DEMO_3 } from "data/navigation";
import ncNanoId from "utils/ncNanoId";
import { Categories } from "Services/API/Get";

function Navigation() {

  const [array, setarray] = React.useState<any>(NAVIGATION_DEMO_3);

  const [categories, setcategories] = React.useState([]);

  useEffect(() => {

    const catArray: any = []

    const catArray2: any = []

    const result = categories.reduce((resultArray: any, item: any, index: any) => {
      const chunkIndex = Math.floor(index / 6)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    result.map((item: any, index: any) => {

      let obj = {
        id: ncNanoId(),
        href: "",
        name: "",
        children: item,
      }

      catArray.push(obj);

    })

    categories.map((item: any, index: any) => {

      let obj = {
        id: item.id,
        href: "",
        name: item.name,
        children: [],
      }

      catArray2.push(obj);

    })

    let newArr = [...array]

    newArr[0].type = "megaMenu";
    newArr[0].children = catArray;
    newArr[0].categories = catArray2;

    setarray(newArr);



  }, [categories])

  React.useEffect(() => {

    getallCategory();

  }, []);




  const getallCategory = () => {

    Categories()
      .then((response) => {
        console.log("category>>>>", response?.data);

        let colorsof = response?.data.map((data: any, index: any) => {
          return {
            id: data.mainId, href: "", name: data.maincategory
          };
        });

        setcategories(colorsof);
       
      })
      .catch(() => {
        setcategories([]);
        // this.setState({
        //   array: [],
        //   filter: [],
        //   showPages: 1,
        //   showOff: false,
        // });
      });

  }


  // const catArray: any = []

  // const testCat: any = [
  //   { id: ncNanoId(), href: "", name: "1" },
  //   { id: ncNanoId(), href: "", name: "2" },
  //   { id: ncNanoId(), href: "", name: "3" },
  //   { id: ncNanoId(), href: "", name: "4" },
  //   { id: ncNanoId(), href: "", name: "5" },
  //   { id: ncNanoId(), href: "", name: "6" },
  //   { id: ncNanoId(), href: "", name: "7" },
  //   { id: ncNanoId(), href: "", name: "8" },
  //   { id: ncNanoId(), href: "", name: "9" },
  //   { id: ncNanoId(), href: "", name: "10" },
  //   { id: ncNanoId(), href: "", name: "11" },
  //   { id: ncNanoId(), href: "", name: "12" },
  //   { id: ncNanoId(), href: "", name: "13" },
  //   { id: ncNanoId(), href: "", name: "14" },
  //   { id: ncNanoId(), href: "", name: "15" },
  //   { id: ncNanoId(), href: "", name: "16" },

  // ]

  // const result = testCat.reduce((resultArray: any, item: any, index: any) => {
  //   const chunkIndex = Math.floor(index / 6)

  //   if (!resultArray[chunkIndex]) {
  //     resultArray[chunkIndex] = [] // start a new chunk
  //   }

  //   resultArray[chunkIndex].push(item)

  //   return resultArray
  // }, [])

  // console.log("LLLLLL", result)

  // result.map((item: any, index: any) => {

  //   let obj = {
  //     id: ncNanoId(),
  //     href: "/#",
  //     name: "",
  //     children: item,
  //   }

  //   catArray.push(obj);

  // })


  // array[0].type = "megaMenu";
  // array[0].children = catArray;

  return (
    <ul className="nc-Navigation flex items-center">
      {array.map((item: any) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
