import { NoSymbolIcon, CheckIcon } from "@heroicons/react/24/outline";
import NcInputNumber from "components/NcInputNumberNew";
import Prices from "components/Prices";
import CartCount from "Constant/CartCount";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import CartContext from "Context/CartContext";
import StepsContext from "Context/StepsContext";
import TotalContext from "Context/TotalContext";
import { Product, PRODUCTS } from "data/data";
import jwtDecode from "jwt-decode";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { DeleteCard } from "Services/API/Delete";
import { getCartList } from "Services/API/Get";
import { UpdateCartItems } from "Services/API/Put";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LoadingSpinner from "shared/LoadingSpinner";

const CartPage = () => {
  const history = useHistory();
  const { cart, setCart } = useContext(CartContext);
  const { cartotal, setcartotal } = useContext(TotalContext);




  const [array, setArray] = useState([]);
  const [Total, setTotal] = useState<any>(0);

  const [lopen, setlopen] = useState(true);


  React.useEffect(() => {
    getCart();

  }, [cart]);


  const getCart = () => {
    setlopen(true);
    getCartList()
      .then((res) => {
        console.log("get Cart >>>>>", res.data)
        let cartTotalPrice = 0;
        const abc = res.data?.map((e: any) => {
          const finalProductPrice = e.unitprice?.toFixed(2);

          cartTotalPrice += finalProductPrice * e.qty
          // m = m + e.qty * e.discountedprice;
        });
        setArray(res.data);
        setTotal(cartTotalPrice);
        setcartotal(cartTotalPrice);
        setlopen(false);
        CartCount().then((res) => {
          console.log("count res-------->", res);
          setCart(res);
        });
        // this.setState({
        //   array: res.data,
        //   total: m,
        // });
      })
      .catch((err) => {
        setArray([]);
        setTotal(0);
        setcartotal(0);
        setlopen(false);
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} product add failed`,
        //   open_toast: true,
        // });
      });
  }

  const renderStatusSoldout = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-[10px] text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <NoSymbolIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">Sold Out</span>
      </div>
    );
  };

  const renderStatusInstock = () => {
    return (
      <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-[10px] text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
        <CheckIcon className="w-3.5 h-3.5" />
        <span className="ml-1 leading-none">In Stock</span>
      </div>
    );
  };

  const renderProduct = (item: any, index: number) => {
    const { image, price, name } = item;
    let value = item?.qty;

    const thumblineImage = JSON.parse(
      item.images
    ).filter((item: any) => item.thumbnail === true)[0];

    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            // src={image}
            src={`${IMAGE_SERVER_URL_DEV}${thumblineImage?.path}`}
            alt={item?.productName}
            className="h-full w-full object-contain object-center"
          />
          <Link to={`/product-detail/${item?.productId}`} className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-sm font-semibold">
                  <Link to={`/product-detail/${item?.productId}`}> {item?.productName}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-xs text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.35 1.94995L9.69 3.28992"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.07 11.92L17.19 11.26"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 22H16"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="capitalize">{item?.selectedProductColor}</span>
                  </div>
                  <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M21 9V3H15"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 15V21H9"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 3L13.5 10.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 13.5L3 21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="capitalize">{item?.selectedProductSize}</span>
                  </div>
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  {/* <select
                    name="qty"
                    id="qty"
                    className="form-select text-xs rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select> */}
                  <div className="sm:block text-center relative">
                    <NcInputNumber className="relative z-10" max={item?.availableStocks} defaultValue={item?.qty} qty={item} getCart={getCart} />
                  </div>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-xs font-medium h-full"
                    price={item?.unitprice}
                  />
                </div>
              </div>

              <div className="hidden sm:block text-center relative">
                <NcInputNumber className="relative z-10" max={item?.availableStocks} defaultValue={item?.qty} qty={item} getCart={getCart} />
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={item?.unitprice} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-xs">
            {item?.availableStocks === 0
              ? renderStatusSoldout()
              : renderStatusInstock()}

            <a
              // href="##"
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-xs cursor-pointer"
            >
              <span onClick={() => {
                setlopen(true);
                DeleteCard(item?.cartId)
                  .then((res) => {
                    CartCount().then((res) => {
                      console.log("count res-------->", res);
                      setCart(res);
                    });
                    // CartCount().then((res) => {
                    //   console.log("count res-------->", res);
                    //   setCart(res);
                    // });
                    // addToast(`${single.productName} Removed`, { appearance: "error", autoDismiss: true });
                    getCart();

                    //  this.setState(
                    //    {
                    //      open_toast: true,
                    //      alertClass: "error",
                    //      msg: `${data.displayName} Removed`,
                    //      color: "#635bff",
                    //    },
                    //    () => this.getAllCart()
                    //  );
                  })
                  .catch((error) => {
                    setlopen(false);
                    console.error("delete cart item error", error);
                  }

                  );
              }}>Remove</span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-CartPage">
      <LoadingSpinner open={lopen} />
      <Helmet>
        <title>Shopping Cart || Ecomfixerr</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-xl sm:text-2xl lg:text-3xl font-semibold ">
            Shopping Cart
          </h2>
          <div className="block mt-3 sm:mt-5 text-[10px] sm:text-xs font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Homepage
            </Link>
            <span className="text-[10px] mx-1 sm:mx-1.5">/</span>
            <Link to={"/#"} className="">
              Clothing Categories
            </Link>
            <span className="text-[10px] mx-1 sm:mx-1.5">/</span>
            <span className="underline">Shopping Cart</span>
          </div>
        </div>

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />

        {
          array.length > 0 ?
            <>
              <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-200 dark:divide-slate-700 ">
                  {array.map(
                    (item, index) => renderProduct(item, index)
                  )}
                </div>
                <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
                <div className="flex-1">
                  <div className="sticky top-28">
                    <h3 className="text-base font-semibold ">Order Summary</h3>
                    <div className="mt-7 text-xs text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">

                      {array.map(
                        (item: any, index: any) =>
                          <div className="flex justify-between pb-4 pt-4">
                            <span className="w-8/12">{`${item?.productName} ( $${item.unitprice.toFixed(2)} ) x ${item?.qty}`}</span>
                            <span className="font-semibold text-slate-900 dark:text-slate-200 whitespace-nowrap">
                              {`$ ${(item.qty * item.unitprice).toFixed(2)}`}
                            </span>

                          </div>
                      )}




                    </div>
                    <div className="mt-7 text-xs text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                      {/* <div className="flex justify-between pb-4">
                        <span>Subtotal</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          {`$ ${Total.toFixed(2)}`}
                        </span>
                      </div> */}
                      {/* <div className="flex justify-between pb-4">
                        <span>Shipping</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          {`$ ${16.95}`}
                        </span>
                      </div>
                      <div className="flex justify-between pb-4">
                        <span>Tax (10% GST)</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          {`$ ${((Total + 16.95) * 10 / 100).toFixed(2)}`}
                        </span>
                      </div> */}
                      {/* <div className="flex justify-between py-4">
                  <span>Shpping estimate</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    $5.00
                  </span>
                </div>
                <div className="flex justify-between py-4">
                  <span>Tax estimate</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    $24.90
                  </span>
                </div> */}
                      <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-sm pt-4">
                        <span>Order total</span>
                        {/* <span>{`$ ${((Total + 16.95) * 110 / 100).toFixed(2)}`}</span> */}
                        <span>{`$ ${(Total).toFixed(2)}`}</span>
                      </div>
                    </div>
                    <a href="/#/checkout">
                      <ButtonPrimary className="mt-8 w-full">Checkout</ButtonPrimary>
                    </a>
                    {/* <div className="mt-5 text-xs text-slate-500 dark:text-slate-400 flex items-center justify-center">
                      <p className="block relative pl-5">
                        <svg
                          className="w-4 h-4 absolute -left-1 top-0.5"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 8V13"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.9945 16H12.0035"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Learn more{` `}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="##"
                          className="text-slate-900 dark:text-slate-200 underline font-medium"
                        >
                          Taxes
                        </a>
                        <span>
                          {` `}and{` `}
                        </span>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="##"
                          className="text-slate-900 dark:text-slate-200 underline font-medium"
                        >
                          Shipping
                        </a>
                        {` `} infomation
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>

            </>
            :
            <>
              <div className="flex !mt-20 justify-center items-center">
                <p className="text-lg font-semibold">
                  {/* #WU3746HGG12 */}
                  No items added to cart
                </p>

              </div>
              <div className="flex mt-6 justify-center items-center">

                <a href="/#/shop" >
                  <ButtonPrimary>Shop Now</ButtonPrimary>
                </a>
              </div>

            </>
        }


      </main>
    </div>
  );
};

export default CartPage;
