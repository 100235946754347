// import rightImg from "images/hero-right1.png";
import rightImg from "images/about/1.jpg";
import React, { FC } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";

export interface PageAboutProps {
    className?: string;
}

const FOUNDER_DEMO: People[] = [
    {
        id: "1",
        name: `Niamh O'Shea`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "4",
        name: `Danien Jame`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "3",
        name: `Orla Dwyer`,
        job: "Co-founder, Chairman",
        avatar:
            "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "2",
        name: `Dara Frazier`,
        job: "Co-Founder, Chief Strategy Officer",
        avatar:
            "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
];


const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>Finance || Ecomfixerr</title>
            </Helmet>

            {/* ======== BG GLASS ======== */}
            {/* <BgGlassmorphism /> */}

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-14">
                {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}

                <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-1 gap-10 ">

                    <div className="">

                        <h2 className="text-lg font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
                            FINANCING
                        </h2>
                        <div className="text-left">
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-6">
                                Oz cleaning offers financing on all machines valued at $3000 or over. Choose from financing plans ranging from 24, 36, 48 and 60 months and pay the option that best suits your budget.
                            </span>
                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Perfect for when you need a sweeper or a scrubber long term without breaking the bank.
                            </span>

                            <div
                                id=""
                                className="prose prose-sm !max-w-screen-2xl sm:prose lg:prose-lg mx-auto text-neutral-6000 dark:text-neutral-400"
                            >
                                <ol>
                                    <li><text className="font-bold prose prose-sm  sm:prose lg:prose-lg dark:prose-invert">Rent to Buy</text> – You have the option to buy the product at the end of your rental period for the total of 3 monthly payments. For example, if your rental term was $50 per month for 36 months, at the end of the 36 months, you can buy the machinery for $150. </li>
                                    <li><text className="font-bold prose prose-sm  sm:prose lg:prose-lg dark:prose-invert">Upgrade</text> – If you feel as though you need a new machine to meet your needs, you can simply exchange your current machine for a different one. Either at the end or during your rental term, payment plan will then be adjusted based on your upgraded product.</li>
                                    <li><text className="font-bold prose prose-sm  sm:prose lg:prose-lg dark:prose-invert">Continue</text> Renting – You can simply continue renting the product, even after the rental term until you decide with you want to do with the machinery.</li>
                                    <li><text className="font-bold prose prose-sm  sm:prose lg:prose-lg dark:prose-invert">Return</text> – If you neither want to buy or upgrade the machine, you can return the machine and end all further payments.</li>
                                </ol>
                            </div>

                            <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                For more information, please contact us on <text className="font-semibold prose prose-sm  sm:prose lg:prose-lg dark:prose-invert">(03) 9794 0654</text> or <text className="font-semibold prose prose-sm  sm:prose lg:prose-lg dark:prose-invert">info@ozcleaningsupplies.com.au</text>
                            </span>



                            {/* <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8 mt-6">
                {FOUNDER_DEMO.map((item) => (
                  <div key={item.id} className="max-w-sm">
                    <NcImage
                      containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                      className="absolute inset-0 object-cover"
                      src={item.avatar}
                    />
                    <h3 className="text-base font-semibold text-neutral-900 mt-4 md:text-lg dark:text-neutral-200">
                      {item.name}
                    </h3>
                    <span className="block text-xs text-neutral-500 sm:text-sm dark:text-neutral-400">
                      {item.job}
                    </span>
                  </div>
                ))}
              </div> */}

                        </div>
                    </div>

                </div>



                {/* <SectionFounder /> */}
                {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic /> */}

                {/* <SectionPromo3 /> */}
            </div>
        </div>
    );
};

export default PageAbout;
