import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/rightLargeImg.png";
import rightLargeImgDark from "images/rightLargeImgDark.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import cover from "images/cover/cover-8.png"
import cover2 from "images/cover/cover-3.png"
import { NavLink } from "react-router-dom";

export interface SectionPromo1Props {
  className?: string;
  bg?:any;
}

const SectionPromo1: FC<SectionPromo1Props> = ({ className = "",bg = cover }) => {
  return (
    <div
      className={`relative flex flex-col lg:flex-row items-center ${className}`}
    >
      <NavLink to={{pathname:"/shop"}} >
      {/* <img
        className="hidden md:block relative w-full h-full object-cover"
        // src={backgroundLineSvg}
        src={cover}
        alt=""
      />
       <img
        className="block md:hidden sm:relative sm:w-full sm:h-full sm:object-cover"
        // src={backgroundLineSvg}
        src={cover2}
        alt=""
      /> */}

      <div className="absolute inset-0">
        <img
          className="absolute w-full h-full object-cover dark:opacity-5"
          // src={backgroundLineSvg}
          src={bg}
          alt="backgroundLineSvg"
        />
        {/* <img
          className="visible md:invisible absolute w-full h-full object-cover dark:opacity-5"
          // src={backgroundLineSvg}
          src={cover2}
          alt="backgroundLineSvg"
        /> */}
      </div>
      <div className="lg:w-[800px] max-w-lg h-[180px] md:h-[300px] xl:h-[290px] 2xl:h-[300px]  relative"></div>

      

      {/* <div className="relative flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo className="w-28" />
        <h2 className="font-semibold text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl mt-6 sm:mt-10 !leading-[1.2] tracking-tight">
          Earn free money <br /> with Ecomfixerr
        </h2>
        <span className="block mt-6 text-slate-500 dark:text-slate-400 ">
          With Ecomfixerr you will get freeship & savings combo...
        </span>
        <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
          <ButtonPrimary href="/page-collection" className="">
            Savings combo
          </ButtonPrimary>
          <ButtonSecondary
            href="/page-search"
            className="border border-slate-100 dark:border-slate-700"
          >
            Discover more
          </ButtonSecondary>
        </div>
      </div> */}
      {/* <div className="relative flex-1 max-w-xl lg:max-w-none">
        <NcImage containerClassName="block dark:hidden" src={rightImgDemo} />
        <NcImage
          containerClassName="hidden dark:block"
          src={rightLargeImgDark}
        />
        
      </div> */}
      </NavLink>
    </div>
  );
};

export default SectionPromo1;
