import React from "react";
import { Toaster } from "react-hot-toast";
import MyRouter from "routers/index";
import { setSiteVisits } from "Services/API/Put";
function App() {
  React.useEffect(() => {

    setSiteVisits()
      .then((res) => {
        console.log("sitevisit>>>>", res);
      })
      .catch((error) => {
        console.error(">errorrrrr>", error);

      });

  })

  return (
    <>
      <div>
        <Toaster />
      </div>
      {/* MAIN APP */}
      <div className="bg-white text-sm dark:bg-slate-900 text-slate-900 dark:text-slate-200">
        <MyRouter />
      </div>
    </>
  );
}

export default App;
