import React, { FC, useEffect, useId, useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCardNew";
import { Product, PRODUCTS } from "data/data";
import { getAllProducts } from "Services/API/Get";

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Product[];
  mainCategory?:any
}

const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({
  className = "",
  itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  subHeading = "REY backpacks & bags",
  data = PRODUCTS.filter((_, i) => i < 8 && i > 2),
  mainCategory
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  const [allProducts, setallProducts] = React.useState([]);

  const getNewArrival = async () => {



    await getAllProducts({
      pto: Number.MAX_SAFE_INTEGER,
      pfrom: 0,
      sort: {
        sortField: "productId",
        sort: "asc",
      },
      pNo: "1",
      limit: "12",
      // latest: 1,

      manufacturer: JSON.stringify([]),
      categories: mainCategory !== "" && mainCategory !== "all" ? JSON.stringify([mainCategory]) : JSON.stringify([]),
    })
      .then((res) => {
        console.log("this is from new arrival before2222", res.data.result);

        if (res?.data?.result?.length !== 0) {
          setallProducts(res.data.result)
          // this.setState(
          //   {
          //     array: res.data.result,
          //     showOff: true,
          //   },
          //   () => console.log("this is from new arrival after", this.state.array)
          // );
        }
      })
      .catch((error) => {
        console.error(">errorrrrr>", error);
        setallProducts([])
        // this.setState({
        //   array: [],
        //   showOff: false,
        // });
      });

  };

  useEffect(() => {

    getNewArrival();
   
  }, [mainCategory])
  

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 2,
        },
        500: {
          gap: 20,
          perView: 2,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS,allProducts]);

  return (
    <div className={`nc-SectionSliderProductCard ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading
          className={headingClassName}
          fontClass={headingFontClassName}
          rightDescText={subHeading}
          hasNextPrev
        >
          {heading || `New Arrivals`}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {allProducts.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <ProductCard data2={item} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderProductCard;
