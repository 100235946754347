import Axios from "../../Axios";
import axios from "axios";
import jwtDecode from "jwt-decode";
const getToken = localStorage.getItem(`${window.location.hostname}.login`);
console.log("is token avialble", getToken);



let myId = "";
if (getToken) {
  const { data } = JSON.parse(getToken);
  const { id } = jwtDecode(data);
  myId = id;
} else {
  let tempId = localStorage.getItem("tempUser")
  console.log("is yemp avialble", tempId);

  myId = tempId
}

export const CurrentUser = async () => {
  return await Axios({
    pathname: `api/v1/user/${myId}`,
    method: "get",
  });
};

export const getBillInfo = async () => {
  return await Axios({
    pathname: `api/v1/billingInformation/${myId}`,
    method: "get",
  });
};
export const getShipInfo = async () => {
  return await Axios({
    pathname: `api/v1/shippingInformation/${myId}`,
    method: "get",
  });
};
export const getUser = async () => {
  return await Axios({
    pathname: `api/v1/user/${myId}`,
    method: "get",
  });
};
export const getAllProducts = async ({
  pto = Number.MAX_SAFE_INTEGER,
  pfrom = 0,
  sort = {
    sortField: "productId",
    sort: "asc",
  },
  pNo = "1",
  limit = Number.MAX_SAFE_INTEGER.toString(),
  featured = "",
  latest = "",
  top = "",
  dfrom = "0",
  dto = "100",
  manufacturer = JSON.stringify([]),
  categories = JSON.stringify([]),
  search = "",
  subId = JSON.stringify([]),
  distinct = "",
  color = "",
  size = "",
}) => {
  return await Axios({
    pathname: `api/v1/product?subId=${subId}&fromPrice=${pfrom}&toPrice=${pto}&sortField=${sort?.sortField}&sortValue=${sort?.sort}&page=${pNo}&limit=${limit}&top=${top}&fromDiscount=${dfrom}&toDiscount=${dto}&mainId=${categories}&manufacturer=${manufacturer}&featured=${featured}&sub2Id=[]&productName=${search}&latest=${latest}&isActive=1&distinct=${distinct}&size=${size}&colour=${color}`,
    method: "get",
  });
};
export const Categories = async () => {
  return await Axios({
    pathname: "api/v1/category/maincategory?auth",
    method: "get",
  });
};
export const Supplier = async () => {
  return await Axios({
    pathname: "api/v1/supplier",
    method: "get",
  });
};
export const getProductById = async (id) => {
  return await Axios({
    pathname: `api/v1/product/${encodeURIComponent(id)}`,
    method: "get",
  });
};
export const getProductById2 = async (id) => {
  return await Axios({
    pathname: `api/v1/product/prodId/${encodeURIComponent(id)}`,
    method: "get",
  });
};
export const getProductById3 = async (id) => {
  return await Axios({
    pathname: `api/v1/product/catId/${id}`,
    method: "get",
  });
};
export const getCartList = async () => {
  return await Axios({
    pathname: `api/v1/cart?displayName=&fromPrice=0&toPrice=${Number.MAX_SAFE_INTEGER}&sortField=displayName&sortValue=asc&page=1&limit=10&userId=${myId}
    `,
    method: "get",
  });
};
export const getWishlistList = async ({
  limit = Number.MAX_SAFE_INTEGER
}) => {
  return await Axios({
    pathname: `api/v1/wishlist?displayName=&fromPrice=0&toPrice=${Number.MAX_SAFE_INTEGER}&sortField=displayName&sortValue=asc&page=1&limit=${limit}&userId=${myId}
    `,
    method: "get",
  });
};
export const getOrderHistory = async () => {
  return await Axios({
    pathname: `api/v1/orderhistory/user/${myId}`,
    method: "get",
  });
};
export const getProductComments = async (productId) => {
  return await Axios({
    pathname: `api/v1/comment/?productId=${productId}`,
    method: "get",
  });
};

export const getSubCategory = async (mainId) => {
  return await Axios({
    pathname: `api/v1/category/subcategory?mainId=${mainId}`,
    method: "get",
  });
};
export const getAboutus = async () => {
  return await Axios({
    pathname: "api/v1/aboutus",
    method: "get",
  });
};

export const getFaq = async () => {
  return await Axios({
    pathname: "api/v1/faq",
    method: "get",
  });
};

export const getLogo = async () => {
  return await Axios({
    pathname: "api/v1/settings/logo",
    method: "get",
  });
};

export const getCover = async () => {
  return await Axios({
    pathname: "api/v1/settings/cover",
    method: "get",
  });
};

export const getBanner = async () => {
  return await Axios({
    pathname: "api/v1/settings/banner",
    method: "get",
  });
};

export const getIcon = async () => {
  return await Axios({
    pathname: "api/v1/settings/icon",
    method: "get",
  });
};

export const getCaption = async () => {
  return await Axios({
    pathname: "api/v1/settings/caption",
    method: "get",
  });
};

export const getBrand = async () => {
  return await Axios({
    pathname: "api/v1/settings/brand",
    method: "get",
  });
};

export const getDownloadable = async () => {
  return await Axios({
    pathname: "api/v1/settings/downloadable",
    method: "get",
  });
};
export const getProductByName = async ({ name }) => {
  return await Axios({
    pathname: `api/v1/product/getByName/${encodeURIComponent(name)}`,
    method: "get",
  });
};
export const getProductBySizeAndColor = async ({ name, size, color }) => {
  return await Axios({
    pathname: `api/v1/product/getBySizeColour/${encodeURIComponent(name)}/${encodeURIComponent(size)}/${encodeURIComponent(color)}
    `,
    method: "get",
  });
};
export const getProductBySizeAndMaterial = async ({ name, size, material }) => {
  return await Axios({
    pathname: `api/v1/product/prod/getBySizeMaterial?name=${encodeURIComponent(name)}&size=${size}&material=${material}
    `,
    method: "get",
  });
};

export const getProductBySizeAndColorNew = async ({ name, size, colour }) => {
  return await Axios({
    pathname: `api/v1/product/prod/getBySizeColour?name=${encodeURIComponent(name)}&size=${size}&colour=${encodeURIComponent(colour)}
    `,
    method: "get",
  });
};
export const getCompany = async () => {
  return await Axios({
    pathname: "api/v1/settings/company",
    method: "get",
  });
};

export const getOrderDetails = async () => {
  return await Axios({
    pathname: "api/v1/orderhistory",
    method: "get",
  });
};
export const getEmailTemplate = async () => {
  return await Axios({
    pathname: "api/v1/email/customertemplate/",
    method: "get",
  });
};

export const getTestimonial = async () => {
  return await Axios({
    pathname: "api/v1/settings/testimonial",
    method: "get",
  });
};

export const getSignUpVerification = async (token) => {
  return await Axios({
    pathname: `api/v1/user/verification/${token}`,
    method: "get",
  });
};

export const getDistricts = async () => {
  // const result = await axios({
  //   method: "get",
  //   url: `https://locatesrilanka.herokuapp.com/districts`,

  //   data: "",
  //   // headers: {
  //   //   Accept: `application/json`,
  //   //   Authorization: `Bearer ${token}`,
  //   //   "Content-Type": "application/json",
  //   // },
  // });

  // const resultData = result.data;
  // return resultData;
  return await Axios({
    pathname: "api/v1/geo/states/",
    method: "get",
  });
};

export const getCities = async (id) => {
  // const result = await axios({
  //   method: "get",
  //   url: `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-australia-state-suburb&q=&rows=9999&${id ? "refine.ste_name=" + id : "refine"}`,

  //   data: "",
  //   // headers: {
  //   //   Accept: `application/json`,
  //   //   Authorization: `Bearer ${token}`,
  //   //   "Content-Type": "application/json",
  //   // },
  // });

  // const resultData = result;
  // return resultData;

  return await Axios({
    pathname: `api/v1/geo/suburbs/${id}`,
    method: "get",
  });
};

export const getAllCities = async () => {
  // const result = await axios({
  //   method: "get",
  //   url: `https://locatesrilanka.herokuapp.com/cities/`,

  //   data: "",
  //   // headers: {
  //   //   Accept: `application/json`,
  //   //   Authorization: `Bearer ${token}`,
  //   //   "Content-Type": "application/json",
  //   // },
  // });

  // const resultData = result.data;
  // return resultData;

  return await Axios({
    pathname: `api/v1/geo/suburbs`,
    method: "get",
  });
};

export const getProductBySizeAndColorId = async ({ productId, size, colour }) => {
  return await Axios({
    pathname: `api/v1/product/prod/getBySizeColour?productId=${encodeURIComponent(productId)}&size=${size}&colour=${encodeURIComponent(colour)}
    `,
    method: "get",
  });
};

