import Label from "components/Label/Label";
import NcInputNumber from "components/NcInputNumber";
import NcInputNumberNew from "components/NcInputNumberNew";
import Prices from "components/Prices";
import { Product, PRODUCTS } from "data/data";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink, useHistory } from "react-router-dom";
import ContactInfo from "./ContactInfo";
import PaymentMethod from "./PaymentMethod";
import ShippingAddress from "./ShippingAddress";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "shared/Select/Select";
import CartContext from "Context/CartContext";
import TotalContext from "Context/TotalContext";
import { CurrentUser, getAllCities, getCartList, getDistricts, getCities, getProductById, getOrderDetails, getProductById2 } from "Services/API/Get";
import CartCount from "Constant/CartCount";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import { DeleteCard } from "Services/API/Delete";
import { Autocomplete } from "@mui/material";
import { OrderSave, RegisterUser } from "Services/API/Post";
import { EMAIL_TAMPLETE } from "./email";
import { EMAIL_TAMPLETE2 } from "./email2";
import { upperFirst, lowerCase } from 'lodash';
import { EditUserQuery } from "Services/API/Put";
import moment from "moment"
import { addToast } from "shared/Toast";
import _ from "lodash";
import LoadingSpinner from "shared/LoadingSpinner";
import SubData from "./australian_postcodes.json";


const CheckoutPage = () => {



  const [newSubArray, setnewSubArray] = useState<any>([]);

  const [newSubs, setnewSubs] = useState<any>([]);

  const [newSubs2, setnewSubs2] = useState([]);


  const history = useHistory()

  const [tabActive, setTabActive] = useState<
    "ContactInfo" | "ShippingAddress" | "PaymentMethod"
  >("ShippingAddress");

  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };


  const { cart, setCart } = useContext(CartContext);
  const { cartotal, setcartotal } = useContext(TotalContext);


  const [array, setArray] = useState([]);
  const [Total, setTotal] = useState<any>(0);

  const [lopen, setlopen] = useState(true);

  const [cartproducts, setcartproducts] = useState<any>([]);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [homeNo, sethomeNo] = useState("");
  const [country, setcountry] = useState("Australia");
  const [homeAddress, sethomeAddress] = useState("");
  const [homeDistrict, sethomeDistrict] = useState("");
  const [homeCity, sethomeCity] = useState("");
  const [homepostCode, sethomepostCode] = useState("");
  const [userId, setuserId] = useState("");
  const [notes, setnotes] = useState("");
  const [currUser, setcurrUser] = useState<any>("");


  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errcountry, seterrcountry] = useState(false);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  const [errhomeAddress, seterrhomeAddress] = useState(false);

  const [tempUser, settempUser] = useState<any>(null);

  const [delType, setdelType] = useState("delivery");

  const [payDisable, setpayDisable] = useState(true);

  const [stDisable, setstDisable] = useState(true);

  React.useEffect(() => {

    let newSubArray = JSON.parse(JSON.stringify(SubData));

    newSubArray = newSubArray?.map((d: any) => {
      if (d.type === "Delivery Area") {

        return {
          ...d,
          locality: upperFirst(lowerCase(d.locality)),
        };

      }

    });

    newSubArray = _.compact(newSubArray)

    setnewSubArray(_.orderBy(newSubArray, ['locality'], ['asc']));

    console.log(">>>>>>>subs", newSubArray.filter((u: any) => u.state === 0))


  }, []);

  React.useEffect(() => {
    getCart();

  }, [cart]);

  React.useEffect(() => {
    // getCurrentUser();
    listDistricts();
    listHomecities2();

  }, []);

  React.useEffect(() => {
    getCurrentUser();
   

  }, [newSubArray]);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      // listHomecities();
    }
  }, [homeDistrictId]);

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrict)
      .then((res) => {
        console.log("cities>>>", res);
        setallHCities(res.data.records);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([])
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const listHomecities2 = () => {
    getCities("VIC")
      .then((res) => {
        console.log("cities>>>", res);
        setallHCities(res.data.records);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([])
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e?: any, value?: any) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);

      sethomeCity("");
      sethomepostCode("");

      if (value.id !== "VIC") {
        // addToast("Please contact us for customize quotation for delivery", "warning", 4000, true);
        setstDisable(true);
        setpayDisable(true);

      } else {
        setstDisable(false);
        // setpayDisable(false);
      }

      console.log("SDSDSDSD", newSubArray.filter((u: any) => u.state === value.id))

      setnewSubs(newSubArray.filter((u: any) => u.state === value.id))
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    console.log("state>>", value);
  };
  const handleChangeHcity = (e?: any, value?: any) => {
    if (value !== null) {
      sethomeCity(value);
      // sethomepostCode(value.postcode);
      let postCode: any = newSubs.find((v: any) => v.locality === value).postcode
      sethomepostCode(postCode)
      console.log(">>>>>>>", allHCities)

      const founded = allHCities.find((u: any) => _.lowerCase(u.name).trim() === _.lowerCase(value).trim() && parseInt(u.postcode) === parseInt(postCode))
      // const founded = allHCities.find((u: any) => parseInt(u.postcode) === parseInt(postCode))


      if (!founded) {
        // addToast("Please contact us for customize quotation for delivery", "warning", 4000, true);
        setpayDisable(true);

      } else {
        setpayDisable(false);

      }


      seterrhomeCity(false);
      seterrhomepostCode(false);
    } else {
      sethomeCity("");
      sethomepostCode("");

    }

    console.log("city>>", value);
  };



  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {

        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          console.log("<><><><>", cData);
          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);
          setfirstName(cData?.firstName);
          setlasttName(cData?.lastName);
          setemail(cData?.email);
          setmobile(cData?.mobileNumber);
          sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
          sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
          sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
          sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
          sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
          setuserId(cData?.id);
          setpayDisable(false);
          setstDisable(false);

          getDistricts()
            .then((res) => {

              var mobile22 = res.data?.filter(function (el: any) {
                return el.name_en === cData?.homeDistrict;
              });

              console.log(">>>mob22>>", mobile22)

              sethomeDistrictId(mobile22.length > 0 ? mobile22[0]?.id : 0)

              setnewSubs(newSubArray.filter((u: any) => u.state === (mobile22.length > 0 ? mobile22[0]?.id : 0)))

              // setallODistricts(res.data);
            })
            .catch((error) => {
              console.log("Error", error.response);
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });



          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };


  const getCart = () => {
    setlopen(true);
    getCartList()
      .then((res) => {
        console.log("get Cart >>>>>", res.data)
        let cartTotalPrice = 0;
        const abc = res.data?.map((e: any) => {
          const finalProductPrice = e.unitprice?.toFixed(2);

          cartTotalPrice += finalProductPrice * e.qty
          // m = m + e.qty * e.discountedprice;
        });
        setArray(res.data);
        setTotal(cartTotalPrice);
        setcartotal(cartTotalPrice);
        setlopen(false);
        CartCount().then((res) => {
          console.log("count res-------->", res);
          setCart(res);
        });

        const newCart = res.data?.map((item: any, index: any) => {

          return {
            ...item,
            // images : JSON.parse(item.images),
          };
        });

        const gCart = newCart?.map((item: any, index: any) => {
          getProductById2(item?.id)
            .then((res) => {
              delete res.data[0].images;
              delete res.data[0].description;
              delete res.data[0].options;
              delete res.data[0].priceDetails;
              delete res.data[0].imgArray;



              let unitPrice: any = 0;
              let discount = item.discount;
              let cartQty = item.qty >= 1 ? item.qty : 1;




              // const discountedPrice = cartItem.discountedprice;
              // const finalProductPrice = cartItem.unitprice?.toFixed(2);
              // const finalDiscountedPrice = cartItem.discountedprice?.toFixed(2);




              // this.setState(
              //   {
              //     product: res.data[0],
              //   },
              //   () => {
              //     console.log(">>>>>>>>>>>>>>", this.state.product);
              //   }
              // )
              newCart[index].productDetails = res.data[0];
              newCart[index].productDetails.qty = item.qty;
              newCart[index].productDetails.productPrice = item.unitprice;
              // newCart[index].productDetails.id = item.productId;
              newCart[index].productDetails.design = item.design;
            })
            .catch((error) => {
              console.log("Error", error.response);
              // this.setState({ product: "" });
            });

          // newItemList[index].productDetails = this.state.product;
          // newItemList[index].qty = item.qtyOrderd;
          // newItemList[index].id = item.productId;
          // delete newItemList[index].discountedprice;
          // delete newItemList[index].qtyOrderd;
          // delete newItemList[index].images;
        });



        console.log("New Item >>", newCart);
        setcartproducts(newCart);
        // this.setState({
        //   array: res.data,
        //   total: m,
        // });
      })
      .catch((err) => {
        setArray([]);
        setTotal(0);
        setcartotal(0);
        setlopen(false);
        // this.setState({
        //   color2: "#645bff",
        //   alertClass: "success",
        //   msg: `${this.state.productData?.displayName} product add failed`,
        //   open_toast: true,
        // });
      });
  }


  const renderProduct = (item: Product, index: number) => {
    const { image, price, name } = item;

    return (
      <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
        <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={image}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link to="/product-detail" className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-sm font-semibold">
                  <Link to="/product-detail">{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-xs text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.35 1.94995L9.69 3.28992"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.07 11.92L17.19 11.26"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 22H16"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span>{`Black`}</span>
                  </div>
                  <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M21 9V3H15"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 15V21H9"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 3L13.5 10.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 13.5L3 21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span>{`2XL`}</span>
                  </div>
                </div>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <select
                    name="qty"
                    id="qty"
                    className="form-select text-xs rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-xs font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={price} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-xs">
            <div className="hidden sm:block text-center relative">
              <NcInputNumber className="relative z-10" />
            </div>

            <a
              href="##"
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-xs "
            >
              <span>Remove</span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderProduct2 = (item: any, index: number) => {
    const { image, price, name } = item;
    let value = item?.qty;

    const thumblineImage = JSON.parse(
      item.images
    ).filter((item: any) => item.thumbnail === true)[0];

    return (
      <div
        key={index}
        className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0"
      >
        <div className="relative h-36 w-24 sm:w-32 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            // src={image}
            src={`${IMAGE_SERVER_URL_DEV}${thumblineImage?.path}`}
            alt={item?.productName}
            className="h-full w-full object-contain object-center"
          />
          <Link to={`/product-detail/${item?.productId}`} className="absolute inset-0"></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-sm font-semibold">
                  <Link to={`/product-detail/${item?.productId}`}> {item?.productName}</Link>
                </h3>
                <span className="flex mt-2">
                  SKU :
                  <span className="ml-1 font-semibold">{item?.sku}</span>
                </span>
                <div className="mt-1.5 sm:mt-2.5 flex text-xs text-slate-600 dark:text-slate-300">
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.35 1.94995L9.69 3.28992"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.07 11.92L17.19 11.26"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 22H16"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="capitalize">{item?.selectedProductColor}</span>
                  </div>
                  <span className="mx-4 border-l border-slate-200 dark:border-slate-700 "></span>
                  <div className="flex items-center space-x-1.5">
                    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M21 9V3H15"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 15V21H9"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 3L13.5 10.5"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 13.5L3 21"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="capitalize">{item?.selectedProductSize}</span>
                  </div>
                </div>



                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  {/* <select
                    name="qty"
                    id="qty"
                    className="form-select text-xs rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select> */}
                  <div className="sm:block text-center relative">
                    <NcInputNumberNew className="relative z-10" max={item?.availableStocks} defaultValue={item?.qty} qty={item} getCart={getCart} />
                  </div>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-xs font-medium h-full"
                    price={item?.unitprice}
                  />
                </div>
              </div>

              {/* <div className="hidden sm:block text-center relative">
                <NcInputNumberNew className="relative z-10" max={item?.availableStocks} defaultValue={item?.qty} qty={item} getCart={getCart} />
              </div> */}


              <div className="hidden flex-1 sm:flex justify-end">
                <Prices price={item?.unitprice} className="mt-0.5" />
              </div>
            </div>
          </div>



          <div className="flex mt-auto pt-4 items-end justify-between text-xs">
            {/* {item?.availableStocks === 0
              ? renderStatusSoldout()
              : renderStatusInstock()} */}

            <div className="hidden sm:block text-center relative">
              <NcInputNumberNew className="relative z-10" max={item?.availableStocks} defaultValue={item?.qty} qty={item} getCart={getCart} />
            </div>

            <a
              href="##"
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-xs "
            >
              <span onClick={() => {
                setlopen(true);
                DeleteCard(item?.cartId)
                  .then((res) => {
                    // CartCount().then((res) => {
                    //   console.log("count res-------->", res);
                    //   setCart(res);
                    // });
                    // addToast(`${single.productName} Removed`, { appearance: "error", autoDismiss: true });
                    getCart();

                    //  this.setState(
                    //    {
                    //      open_toast: true,
                    //      alertClass: "error",
                    //      msg: `${data.displayName} Removed`,
                    //      color: "#635bff",
                    //    },
                    //    () => this.getAllCart()
                    //  );
                  })
                  .catch((error) => {
                    setlopen(false);
                    console.error("delete cart item error", error);
                  }

                  );
              }}>Remove</span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderLeft = (isActive: any = true) => {
    return (
      <div className="space-y-8">
        {/* <div id="ContactInfo" className="scroll-mt-24">
          <ContactInfo
            isActive={tabActive === "ContactInfo"}
            onOpenActive={() => {
              setTabActive("ContactInfo");
              handleScrollToEl("ContactInfo");
            }}
            onCloseActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
          />
        </div> */}

        <div id="ShippingAddress" className="scroll-mt-24">
          {/* <ShippingAddress
            isActive={tabActive === "ShippingAddress"}
            onOpenActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
            onCloseActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
          /> */}
          <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
            <div className="p-6 flex flex-col sm:flex-row items-start">
              <span className="hidden sm:block">
                <svg
                  className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>

              <div className="sm:ml-8">
                <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                  <span className="uppercase">Billing Details</span>
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2.5"
                    stroke="currentColor"
                    className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </h3>
                {/* <div className="font-semibold mt-1 text-xs">
              <span className="">
                St. Paul's Road, Norris, SD 57560, Dakota, USA
              </span>
            </div> */}
              </div>
              {/* <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-xs font-medium"
            className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
            onClick={onOpenActive}
          >
            Change
          </ButtonSecondary> */}
            </div>
            <div
              className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${isActive ? "block" : "hidden"
                }`}
            >
              {/* ============ */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-xs">First name</Label>
                  <Input className="mt-1.5"
                    onChange={(e) => {
                      setfirstName(e.target.value);
                      seterrfirstName(false);
                    }}
                    value={firstName}
                  />
                  {errfirstName && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      This Field is Requied
                    </div>
                  )}
                </div>
                <div>
                  <Label className="text-xs">Last name</Label>
                  <Input className="mt-1.5"
                    onChange={(e) => {
                      setlasttName(e.target.value);
                      seterrlasttName(false);
                    }}
                    value={lastName}
                  />
                  {errlastName && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      This Field is Requied
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                <div className="max-w-lg">
                  <Label className="text-xs">Email address</Label>
                  <Input className="mt-1.5" type={"email"}
                    onChange={(e) => {
                      setemail(e.target.value);
                      seterremail(false);
                    }}
                    value={email}
                  />
                  {erremail && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      This Field is Requied
                    </div>
                  )}
                </div>

                <div className="max-w-lg">
                  <Label className="text-xs">Your phone number</Label>
                  <Input className="mt-1.5" placeholder={"+808 xxx"} type={"tel"}
                    onChange={(e) => {
                      setmobile(e.target.value);
                      seterrmobile("");
                    }}
                    value={mobile}
                  />
                  {errmobile && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      {errmobile}
                    </div>
                  )}
                </div>



              </div>

              {/* ============ */}

              <div>
                <Label className="text-xs">Order Type</Label>
                <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
                  {/* <Radio
                    label={`<span class="text-xs font-medium">Home <span class="font-light">(All Day Delivery)</span></span>`}
                    id="Address-type-home"
                    name="Address-type"
                    defaultChecked
                  /> */}
                  <Radio
                    label={`<span class="text-xs font-medium">Delivery </span>`}
                    id="Address-type-home"
                    name="Address-type"
                    checked={delType === "delivery"}
                    onChange={(e) => {
                      setdelType("delivery")
                    }}
                  />
                  <Radio
                    label={`<span class="text-xs font-medium">Pick Up </span>`}
                    id="Address-type-office"
                    name="Address-type"
                    checked={delType === "pickup"}
                    onChange={(e) => {
                      setdelType("pickup")
                    }}
                  />
                </div>
              </div>

              {
                delType === "delivery" &&

                <>
                  <div>
                    <div className="w-full">
                      <Label className="text-xs">Address</Label>
                      <Input
                        className="mt-1.5"
                        placeholder="House number and street name"
                        type="text"
                        onChange={(e) => {
                          sethomeAddress(e.target.value);
                          seterrhomeAddress(false);
                        }}
                        value={homeAddress}
                      />
                      {errhomeAddress && (
                        <div className="text-red-600 text-xs text-right mt-2">
                          This Field is Requied
                        </div>
                      )}
                    </div>
                    {/* <div className="sm:w-1/3">
                  <Label className="text-xs">Apt, Suite *</Label>
                  <Input className="mt-1.5" defaultValue="55U - DD5 " />
                </div> */}
                  </div>

                  {/* ============ */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                    <div>
                      <Label className="text-xs">Country</Label>
                      <Select className="mt-1.5"
                        onChange={(e) => {
                          setcountry(e.target.value);
                          seterrcountry(false);
                        }}
                        value={country}
                      >
                        <option value={""}>Select a country</option>
                        <option value="Australia">Australia</option>
                        {/* <option value="United States">Canada</option>
                    <option value="United States">Mexico</option>
                    <option value="United States">Israel</option>
                    <option value="United States">France</option>
                    <option value="United States">England</option>
                    <option value="United States">Laos</option>
                    <option value="United States">China</option> */}
                      </Select>
                      {errcountry && (
                        <div className="text-red-600 text-xs text-right mt-2">
                          This Field is Requied
                        </div>
                      )}
                    </div>
                    <div>
                      <Label className="text-xs">State</Label>
                      {/* <Input className="mt-1.5" defaultValue="Norris" /> */}
                      <Autocomplete
                        className="mt-1.5"
                        // className="form-group"
                        // className="border-neutral-200"
                        disablePortal
                        defaultValue={{ name_en: homeDistrict }}
                        value={{ name_en: homeDistrict }}

                        // id="combo-box-demo"
                        options={allHDistricts}
                        getOptionLabel={(option) => option.name_en}
                        onChange={(event, value) => {

                          handleChangeHdist(event, value);
                          // setCountry(value);
                        }}
                        // sx={{
                        //   // width: "80vw",
                        //   background:"transparent",
                        //   borderRadius: "20px",
                        //   // border: "none",
                        //   // borderColor: "transparent",

                        //   "& .MuiInputBase-input": {
                        //     height: 15,
                        //     // border: "none",
                        //     fontSize: "14px",
                        //     fontFamily: "'Jost', sans-serif",
                        //     // borderColor: "transparent",
                        //   },
                        //   //view
                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        //     // borderColor: "transparent",
                        //   },

                        //   "&:hover .MuiOutlinedInput-input": {
                        //     color: "black",
                        //   },
                        //   "&:hover .MuiInputLabel-root": {
                        //     color: COLORS.GrayBackground,
                        //   },
                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        //   {
                        //     // borderColor: "transparent",
                        //   },
                        //   ".MuiAutocomplete-root fieldset:hover": {
                        //     color: "transparent",
                        //     // borderColor: "transparent",
                        //   },
                        // }}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <Input {...params.inputProps} className="mt-1.5" placeholder="Select State" />
                          </div>
                        )}
                        ListboxProps={{
                          style: {
                            maxHeight: "150px",
                            fontSize: "14px",
                            // border: '1px solid white'
                          },
                        }}
                      />

                      {errhomeDistrict && (
                        <div className="text-red-600 text-xs text-right mt-2">
                          This Field is Requied
                        </div>
                      )}

                    </div>

                  </div>

                  {/* ============ */}

                  {
                    !stDisable &&

                    <>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                        <div>
                          <Label className="text-xs">Subrub / City</Label>
                          {/* <Input className="mt-1.5" defaultValue="Texas" /> */}

                          <Autocomplete
                            className="mt-1.5"
                            // className="form-group"
                            // className="border-neutral-200"
                            disablePortal
                            // defaultValue={{ fields: { scc_name: homeCity } }}
                            // value={{ fields: { scc_name: homeCity } }}
                            // defaultValue={{ locality: homeCity }}
                            // value={{ locality: homeCity }}
                            value={homeCity}
                            // id="combo-box-demo"
                            options={newSubs2}
                            // getOptionLabel={(option) => option?.fields?.scc_name}
                            // getOptionLabel={(option) => option?.locality}
                            getOptionLabel={(option) => option}
                            onInputChange={(event, value) => {
                              console.log(value)

                              if (value) {

                                let char = lowerCase(value)

                                let arr: any = [...newSubs]

                                // arr = arr.filter((data) => lowerCase(data.locality).includes(char));

                                const det = arr.map((item: any, index: any) => {

                                  if (lowerCase(item.locality).startsWith(char)) {

                                    return { ...item }
                                  }
                                })


                                console.log(">>>>>", _.compact(det))

                                arr = _.compact(det)

                                arr = _.map(arr, "locality")

                                console.log("arr>>", arr)

                                arr = _.uniq(arr);

                                setnewSubs2(arr);

                              } else {
                                setnewSubs2([])
                              }
                            }}

                            onChange={(event, value) => {

                              handleChangeHcity(event, value);
                              // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <Input {...params.inputProps} className="mt-1.5" placeholder="Search Suburb" />

                              </div>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                                fontSize: "14px",
                                // border: '1px solid white'
                              },
                            }}
                          />
                          {errhomeCity && (
                            <div className="text-red-600 text-xs text-right mt-2">
                              This Field is Requied
                            </div>
                          )}

                        </div>
                        <div>
                          <Label className="text-xs">Postal code</Label>
                          <Input className="mt-1.5" value={homepostCode} placeholder="Enter Postcode"
                            onChange={(e) => {
                              sethomepostCode(e.target.value);
                              seterrhomepostCode(false);
                            }}
                          />
                          {errhomepostCode && (
                            <div className="text-red-600 text-xs text-right mt-2">
                              This Field is Requied
                            </div>
                          )}

                        </div>
                      </div>
                    </>
                  }

                </>
              }


              {/* ============ */}


              {/* ============ */}
              {/* <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary
              className="sm:!px-7 shadow-none"
              onClick={onCloseActive}
            >
              Save and next to Payment
            </ButtonPrimary>
            <ButtonSecondary
              className="mt-3 sm:mt-0 sm:ml-3"
              onClick={onCloseActive}
            >
              Cancel
            </ButtonSecondary>
          </div> */}
            </div>
          </div>
        </div>

        {/* <div id="PaymentMethod" className="scroll-mt-24">
          <PaymentMethod
            isActive={tabActive === "PaymentMethod"}
            onOpenActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            onCloseActive={() => setTabActive("PaymentMethod")}
          />
        </div> */}
      </div>
    );
  };

  React.useEffect(() => {

    if (localStorage.getItem("tempUser")) {
      settempUser(localStorage.getItem("tempUser"))
    } else {
      settempUser(null);
    }

  }, [])



  const onSubmit2 = async (e?: any, type?: any) => {

    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    e.preventDefault();

    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }
    if (country === "") {
      seterrcountry(true);
    }
    if (homeAddress === "") {
      seterrhomeAddress(true);
    }
    if (homeCity === "") {
      seterrhomeCity(true);
    }
    if (homeDistrict === "") {
      seterrhomeDistrict(true);
    }
    if (homepostCode === "") {
      seterrhomepostCode(true);
    }


    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This Field is Requied");
      } else {
        seterrmobile("Please Enter Valid number");
      }
    }


    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      firstName: upperFirst(lowerCase(currUser.firstName)),
      lastName: upperFirst(lowerCase(currUser.lastName)),
      mobileNumber: currUser.mobileNumber,
      email: currUser.email,
      homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
      homeAddress: homeAddress,
      homeCity: homeCity,
      password: "",

      homeDistrict: homeDistrict,
      homepostCode: homepostCode,
      note: currUser.note === null ? "" : currUser.note,

    };
    console.log("obj>>>>", obj);

    if (
      (firstName !== "" &&
        lastName !== "" &&
        email !== "" &&
        mobile !== "" &&
        country !== "" &&
        phoneRegex.test(mobile)) &&
      (((homeAddress !== "" &&
        homeCity !== "" &&
        homeDistrict !== "" &&
        homepostCode !== "") && delType === "delivery") || (delType === "pickup"))
      // (password !== "" ? repassword !== "" : firstName !== "") &&



    ) {
      setlopen(true);

      if (!tempUser) {

        EditUserQuery(obj)
          .then((res) => {
            // setlopen(false);
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Update Successful");
            getCurrentUser();

            // seteDisable(true);

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            // setlopen(false);
            console.log("Error", error.response);

            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          })

      } else {

        let obj = {
          data: {
            firstName: upperFirst(lowerCase(firstName)),
            lastName: upperFirst(lowerCase(lastName)),
            mobileNumber: mobile,
            email: email,
            password: "",
            clientType: "individual",
            registered: 0,
            userId: tempUser,
            homeAddress: homeAddress,
            homeCity: homeCity,
            homeDistrict: homeDistrict,
            homepostCode: homepostCode,
          }

        };

        RegisterUser(obj.data)
          .then((res) => {
            // setlopen(false);
            // addToast(`Registration Successful `, "success");
            // history.push("/login");
            // setaKey("login")
            // setuserId(res.data[0]);
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Register Successful");
            // setTimeout(() => {
            //   setlopen(false);
            //   //  setOpen(true);
            //   history.push("/login-register");
            //   // window.location.reload();
            //   // setProgress(false);
            // }, 2000);

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlopen(false);
            // addToast(error.response.data.message, "warning");

            // console.log("Error", error.response);
            // setProgress(false);
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });

      }



      let Subtotal: any = 0;
      let TotalItem: any = 0;
      let orderMyNo: any = 10000;
      let d = moment().format("lll");

      if (cartproducts.length !== 0) {
        console.log("--------------", cartproducts);
        await cartproducts?.map((item: any) => {

          let unitPrice = 0;
          let discount = item.discount;
          let cartQty = item.qty >= 1 ? item.qty : 1;

          // const newArr = JSON.parse(item.priceDetails)?.map(single => {

          //   if (cartQty === single.qty)
          //     unitPrice = single.unitPrice
          // })


          // const discountedPrice = cartItem.discountedprice;
          // const finalProductPrice = cartItem.unitprice?.toFixed(2);
          // const finalDiscountedPrice = cartItem.discountedprice?.toFixed(2);
          // let decPlaces = countDecimals(unitPrice)

          // const finalProductPrice = decPlaces === 2 ? unitPrice?.toFixed(2) : unitPrice?.toFixed(decPlaces)
          // const discountedPrice = parseFloat(unitPrice*discount/100).toFixed(2) 
          // const finalDiscountedPrice = decPlaces === 2 ? parseFloat(unitPrice*(100-discount)/100).toFixed(2) :parseFloat(unitPrice*(100-discount)/100).toFixed(decPlaces) 


          let amountpayable = item?.qty * item?.unitprice;
          let itemTotal = item?.qty * item?.unitprice;
          Subtotal = Subtotal + amountpayable;
          TotalItem = TotalItem + itemTotal;
        });
      } else {
        addToast("Your Cart List Empty", "warning");
        // this.setState({
        //   color: "#635bff",
        //   alertClass: "success",
        //   msg: "Your Cart List Empty",
        //   open_toast: true,
        // });
        return true;
      }

      await getOrderDetails()
        .then((res) => {
          orderMyNo = parseInt(res.data[res.data.length - 1]?.orderNo) + 1;
        })
        .catch((error) => {
          console.error("order history error", error.response);
        });

      const emailTem = await EMAIL_TAMPLETE({
        customerName: firstName,
        invoiceNo: `${orderMyNo}`.trim().toString(),
        pdfName: `${firstName}_${`${orderMyNo}`
          .trim()
          .toString()}`,
      });

      const emailTem2 = await EMAIL_TAMPLETE2({
        customerName: `${firstName} ${lastName}`,
        address: homeAddress,
        address2: `${homeDistrict}`,
        address3: `${homeCity}`,
        address4: `${homepostCode}`,
        date: moment(new Date()).format("l"),
        invoiceNo: `${orderMyNo}`.trim().toString(),
        tax: 0,
        amount: Subtotal,
        MESSAGE: "",
        iUsers: cartproducts,
        currency: "$",
        delType: delType,
        mobileNumber: mobile
      });

      // const emailTem = await EMAIL_TAMPLETE({
      //   date: d,
      //   currency: "$",
      //   amount: Subtotal,
      //   orderNo: orderMyNo,
      //   data: cartproducts,
      //   IMAGE_URL: IMAGE_SERVER_URL_DEV,
      //   MESSAGE: "",
      // });


      const shippingcost = 16.95;

      const obj2 = {
        // productId: item?.productId,
        orderNo: `${orderMyNo}`.trim().toString(),
        invoiceNo: `${orderMyNo}`.trim().toString(),
        userId: !tempUser ? userId : tempUser,
        userName: firstName,
        email: email,
        qty: cartproducts[0].qty,
        productName: cartproducts[0]?.productName,
        productId: cartproducts[0]?.id,
        itemTotal: TotalItem,
        shippingAddress: homeAddress,
        billingAddress: homeAddress,
        productPrice: cartproducts[0]?.discountedprice,
        // shippingCost: shippingcost,
        amountPayable: ((Subtotal) + (delType === "delivery" ? 16.95 : 0)).toFixed(2),
        payment: "Card",
        date: moment().format("lll"),
        productDetails: _.map(cartproducts, "productDetails"),
        image: _.map(cartproducts, "images"),
        customerDetails: {
          customer: currUser,
          billing: obj,
          shipping: obj,
        },
        firstName: firstName,
        lastName: lastName,
        phoneNumber: mobile,
        billingState: homeDistrict,
        shippingState: homeDistrict,
        billingSuburb: homeCity,
        shippingSuburb: homeCity,
        billingpostCode: homepostCode,
        shippingpostCode: homepostCode,
        emailBody: emailTem,
        pdfName: `${firstName}_${`${orderMyNo}`
          .trim()
          .toString()}`,
        emailTemplate: emailTem2,
        subject: "",
        shippingCost: delType === "delivery" ? 16.95 : 0,
        payType: type,
      };

      console.log("Order>>>>", obj2)

      OrderSave(obj2)
        .then((res) => {
          // addToast("Your Order Successfully Sent", { appearance: "success", autoDismiss: true });
          if (res.data !== undefined) {
            window.location.href = res.data;
          } else {
            history.push("/cart");
          }
          // this.setState(
          //   {
          //     color: "#635bff",
          //     alertClass: "success",
          //     msg: "Your Order Successfully Sent",
          //     open_toast: true,
          //   },
          //   () => {
          //     setTimeout(() => {
          //       this.props.Step4Switch();
          //     }, 2000);
          //   }
          // );

        })
        .catch((error) => {
          setlopen(false);
          addToast(error.response.data.message, { appearance: "warning", autoDismiss: true });
          console.log("Error", error.response);
        });






    } else {
      // scrollElement();
    }
  };

  return (
    <div className="nc-CheckoutPage">
      <LoadingSpinner open={lopen} />
      <Helmet>
        <title>Checkout || Ecomfixerr</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-16">
          <h2 className="block text-xl sm:text-2xl lg:text-3xl font-semibold ">
            Checkout
          </h2>
          <div className="block mt-3 sm:mt-5 text-[10px] sm:text-xs font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Homepage
            </Link>
            <span className="text-[10px] mx-1 sm:mx-1.5">/</span>
            <Link to={"/#"} className="">
              Clothing Categories
            </Link>
            <span className="text-[10px] mx-1 sm:mx-1.5">/</span>
            <span className="underline">Checkout</span>
          </div>
        </div>

        {
          array.length > 0 ?

            <>
              <div className="flex flex-col lg:flex-row">
                <div className="flex-1">{renderLeft()}</div>

                <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

                <div className="w-full lg:w-[36%] ">
                  <h3 className="text-base font-semibold">Order summary</h3>
                  <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
                    {array.map(renderProduct2)}
                  </div>



                  <div className="mt-10 pt-6 text-xs text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
                    {/* <div>
                <Label className="text-xs">Discount code</Label>
                <div className="flex mt-1.5">
                  <Input sizeClass="h-10 px-4 py-3" className="flex-1" />
                  <button className="text-neutral-700 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-xs bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors">
                    Apply
                  </button>
                </div>
              </div> */}

                    <div className="mt-7 text-xs text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">

                      {array.map(
                        (item: any, index: any) =>
                          <div className="flex justify-between pb-4 pt-4">
                            <span className="w-7/12">{`${item?.productName} ( $${item.unitprice.toFixed(2)} ) x ${item?.qty}`}</span>
                            <span className="font-semibold text-slate-900 dark:text-slate-200 whitespace-nowrap">
                              {`$ ${(item.qty * item.unitprice).toFixed(2)}`}
                            </span>

                          </div>
                      )}




                    </div>

                    <div className="mt-4 flex justify-between py-0.5">
                      <span>Subtotal</span>
                      <span className="font-semibold text-slate-900 dark:text-slate-200">
                        {`$ ${Total.toFixed(2)}`}
                      </span>
                    </div>

                    {
                      delType === "delivery" &&

                      <div className="mt-4 flex justify-between pb-2.5">
                        <span>Shipping</span>
                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                          {`$ ${16.95}`}
                        </span>
                      </div>
                    }



                    {/* <div className="mt-4 flex justify-between pb-2.5">
                      <span>Tax (10% GST)</span>
                      <span className="font-semibold text-slate-900 dark:text-slate-200">
                        {`$ ${((Total) * 10 / 100).toFixed(2)}`}
                      </span>
                    </div> */}

                    {/* <div className="flex justify-between py-2.5">
                <span>Shipping estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  $5.00
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Tax estimate</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  $24.90
                </span>
              </div> */}
                    <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-sm pt-4">
                      <span>Order total</span>
                      <span> {`$ ${((Total) + (delType === "delivery" ? 16.95 : 0)).toFixed(2)}`}</span>
                    </div>
                  </div>

                  {
                    (payDisable === true && ((homeDistrict !== "" && homeDistrict !== "Victoria") || (homeDistrict === "Victoria" && homeCity !== "")) && delType === "delivery") &&


                    < div className="p-5 w-full bg-[#008500] mt-5 flex justify-center">

                      <span className="text-white text-center">Please contact us for customize quotation for delivery</span>

                    </div>


                  }




                  {
                    (payDisable === false || delType === "pickup") &&
                    <>
                      <ButtonPrimary className="mt-8 w-full"
                        onClick={(e?: any) => {
                          onSubmit2(e, "paypal")
                        }}
                      >
                        Pay with Paypal</ButtonPrimary>

                      <ButtonPrimary className="mt-4 w-full"
                        onClick={(e?: any) => {
                          onSubmit2(e, "stripe")
                        }} >Pay with Credit/Debit Card</ButtonPrimary>

                    </>






                  }

                  <div className="mt-5 text-xs text-slate-500 dark:text-slate-400 flex items-center justify-center">
                    <p className="block relative pl-5">
                      <svg
                        className="w-4 h-4 absolute -left-1 top-0.5"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 8V13"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.9945 16H12.0035"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Learn more{` `}
                      {/* <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="##"
                        className="text-slate-900 dark:text-slate-200 underline font-medium"
                      >
                        Taxes
                      </a>
                      <span>
                        {` `}and{` `}
                      </span> */}
                      <NavLink to={"/shipping"} target="_blank"
                        rel="noopener noreferrer" >
                        <a
                          // target="_blank"
                          // rel="noopener noreferrer"
                          // href=""
                          className="text-slate-900 dark:text-slate-200 underline font-medium"
                        >
                          Shipping
                        </a>
                        {` `} infomation
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>

            </>
            :
            <>
              <div className="flex !mt-20 justify-center items-center">
                <p className="text-lg font-semibold">
                  {/* #WU3746HGG12 */}
                  No items added to cart
                </p>

              </div>
              <div className="flex mt-6 justify-center items-center">

                <a href="/#/shop" >
                  <ButtonPrimary>Shop Now</ButtonPrimary>
                </a>
              </div>

            </>
        }


      </main>
    </div >
  );
};

export default CheckoutPage;
