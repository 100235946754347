import React, { InputHTMLAttributes } from "react";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";


export const addToast = (message?: any, type?: any, time?: any, pay?: any) => {
    toast.custom(
        (t) => (
            <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
            >

                <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                        {
                            type === "success" ?
                                <i className="fa fa-check-circle text-xl text-green-500" aria-hidden="true"></i>

                                :

                                <i className="fa fa-exclamation-triangle text-red-500 text-xl" aria-hidden="true"></i>


                        }

                        {/* <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                      alt=""
                    /> */}
                    </div>
                    <div className="ml-3 flex-1 mt-1">
                        <p className="text-sm font-medium">
                            {message}
                        </p>
                        {/* <p className="mt-1 text-xs text-gray-500">
                      Sure! 8:30pm works great!
                    </p> */}
                    </div>
                </div>

                {/* <div className="border-t border-slate-200 dark:border-slate-700 my-4" /> */}
                {/* {renderProductCartOnNotify({ size })} */}
            </Transition>
        ),
        { position: `${pay ? "bottom-center" : "bottom-left"}`, id: "nc-product-notify", duration: time ? time : 4000 }
    );
};



