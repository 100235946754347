import React, { FC } from "react";
import Card12 from "./Card12";
import Card13 from "./Card13";
import image1 from "images/blogs/2.jpg";
import image2 from "images/blogs/3.jpg";

export interface SectionMagazine5Props {}

const blogs:any=[
  {
    title:"How To Clean Drains Without Chemicals",
    desc:"If you walk into commercial building or household after a comprehensive cleaning session and you’ll find a pristine looking kitchen, bathroom or laundry sink.",
    date:"October 25, 2018",
    href:"/blog-single-2",
    image:image1
  },
  {
    title:"Inside The Mind Of Your Weird Office Cleaning Client",
    desc:"No matter how hard I try, it’s an overwhelming challenge to keep my office, let alone all of my colleague’s desks clean.  I know, I know, we are our...",
    date:"October 14, 2018",
    href:"/blog-single-3",
    image:image2
  },
  
]

const SectionMagazine5: FC<SectionMagazine5Props> = () => {
  
  return (
    <div className="nc-SectionMagazine5">
      <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
        <Card12 />
        <div className="grid gap-6 md:gap-8">
          {blogs.map((item:any, index:any) => (
            <Card13 item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionMagazine5;
