import Axios from "../../Axios";

export const RegisterUser = async (obj) =>
  await Axios({
    pathname: "api/v1/user",
    obj: obj,
  });

export const LoginQuery = async (obj) =>
  await Axios({
    pathname: "api/v1/login",
    obj: obj,
  });
export const AddBillInfo = async (obj) =>
  await Axios({
    pathname: "api/v1/billinginformation",
    obj: obj,
  });
export const AddShipInfo = async (obj) =>
  await Axios({
    pathname: "api/v1/shippingInformation",
    obj: obj,
  });

export const postContacts = async (obj) =>
  await Axios({
    pathname: `api/v1/email`,
    obj: obj,
  });
  export const postQuote = async (obj) =>
  await Axios({
    pathname: `api/v1/email/quote`,
    obj: obj,
  });
  export const addToCard = async (obj) =>
  await Axios({
    pathname: `api/v1/cart`,
    obj: obj,
  });
export const addToWishlist = async (obj) =>
  await Axios({
    pathname: `api/v1/wishlist`,
    obj: obj,
  });

export const OrderSave = async (obj) =>
  await Axios({
    pathname: `api/v1/orderhistory/`,
    obj: obj,
  });
export const CommentSave = async (obj) =>
  await Axios({
    pathname: `api/v1/comment/`,
    obj: obj,
  });
export const AddReply = async (obj) =>
  await Axios({
    pathname: `api/v1/reply/`,
    obj: obj,
  });
  export const resetPassword = async (obj) =>
  await Axios({
    pathname: `api/v1/login/reset`,
    obj: obj,
  });
  export const updatePassword = async (obj) =>
  await Axios({
    pathname: `api/v1/login/update`,
    obj: obj,
  });
  export const signUpVerification = async (obj) =>
  await Axios({
    pathname: `api/v1/user/verification/`,
    obj: obj,
  });

  export const postLogo = async (obj) =>
  await Axios({
    pathname: `api/v1/settings/logo`,
    obj: obj,
  });

  export const postCover = async (obj) =>
  await Axios({
    pathname: `api/v1/settings/cover`,
    obj: obj,
  });

  export const postBanner = async (obj) =>
  await Axios({
    pathname: `api/v1/settings/banner`,
    obj: obj,
  });

  export const postBrand = async (obj) =>
  await Axios({
    pathname: `api/v1/settings/brand`,
    obj: obj,
  });