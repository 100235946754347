import React, { FC, useState, useEffect, useRef } from "react";
// import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import _, { lowerCase, map, upperFirst } from "lodash";
// import { postJobs } from "Services/API/Post";
import { addToast } from "shared/Toast";
import { CurrentUser, getCities, getDistricts, getOrderDetails } from "Services/API/Get";
import { Button, Grid, TextField, Typography, Autocomplete, Box } from "@mui/material";
import { UpdateJobs } from "Services/API/Put";
import { useHistory } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import moment from "moment";
// import SubData from "data/australian_postcodes.json";


import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingSpinner from "shared/LoadingSpinner";
// import DatePicker from "react-multi-date-picker"
import BotToast from "shared/BotToast";
import { postBanner, postLogo } from "Services/API/Post";

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const theme = createTheme({
    palette: {
        primary: {
            main: "#024022",
        },
        secondary: {
            main: "#024022",
        },
    },
});

const CATEG = [{
    value: "Seasonal",
    name: "Seasonal"
},
{
    value: "Casual",
    name: "Casual"
},
{
    value: "Temporary",
    name: "Temporary"
},
// {
//     value: "Fixed",
//     name: "Fixed"
// },
{
    value: "Part Time",
    name: "Permanent – Part Time"
},
{
    value: "Full Time",
    name: "Permanent – Full time"
},


]



const Jobs = ({ selectedJob = null, onCloseModalQuickView }) => {

    const [images, setImages] = useState(selectedJob ? [selectedJob] : []);

    const [title, settitle] = useState(selectedJob ? selectedJob.title : "");
    const [jobType, setjobType] = useState(selectedJob ? selectedJob.jobType : "");
    const [country, setcountry] = useState(selectedJob ? selectedJob.country : "Australia");
    const [homeDistrict, sethomeDistrict] = useState(selectedJob ? selectedJob.state : "");
    const [homeCity, sethomeCity] = useState(selectedJob ? selectedJob.suburb : "");
    const [duration, setduration] = useState(selectedJob ? selectedJob.duration : "");
    const [description, setdescription] = useState(selectedJob ? selectedJob.description : "");
    const [currency, setcurrency] = useState(selectedJob ? selectedJob.currency : "AUD");
    const [rate, setrate] = useState(selectedJob ? selectedJob.hourlyRate : "");
    const [expire, setexpire] = useState(selectedJob ? moment(selectedJob.expiryDate).toDate() : "");
    const [Keywords, setKeywords] = useState("");

    const [oldPkg, setoldPkg] = useState("");

    const [errimages, seterrImages] = useState([]);

    const [errtitle, seterrtitle] = useState(false);
    const [errjobType, seterrjobType] = useState(false);
    const [errcountry, seterrcountry] = useState(false);
    const [errsuburb, seterrsuburb] = useState(false);
    const [errduration, seterrduration] = useState(false);
    const [errdescription, seterrdescription] = useState(false);
    const [errcurrency, seterrcurrency] = useState(false);
    const [errrate, seterrrate] = useState(false);
    const [errexpire, seterrexpire] = useState(false);
    const [errKeywords, seterrKeywords] = useState(false);
    const [errhomeDistrict, seterrhomeDistrict] = useState(false);
    const [errhomeCity, seterrhomeCity] = useState(false);

    const [homeDistrictId, sethomeDistrictId] = useState(0);
    const [allHCities, setallHCities] = useState([]);
    const [allHDistricts, setallHDistricts] = useState([]);

    const [currUser, setcurrUser] = useState("");

    const [packageId, setpackageId] = useState("");

    const [lOpen, setlOpen] = useState(false);

    const [errpackages, seterrpackages] = useState(false);

    const history = useHistory();

    const [newSubArray, setnewSubArray] = useState([]);
    const [newSubs, setnewSubs] = useState([]);

    const [bOpen, setbOpen] = useState(false);
    const [bMsg, setbMsg] = useState("");
    const [bStatus, setbStatus] = useState("");

    const ref = useRef(null);

    const [editorData, setEditorData] = useState(selectedJob.duties ? selectedJob.duties : "<br/><br/><br/><br/><br/>");

    const [newSubs2, setnewSubs2] = useState([]);

    const handleScroll = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: "center", });
    };

    // React.useEffect(() => {

    //     let newSubArray = JSON.parse(JSON.stringify(SubData));

    //     newSubArray = newSubArray?.map((d) => {
    //         if (d.type === "Delivery Area") {

    //             return {
    //                 ...d,
    //                 locality: upperFirst(lowerCase(d.locality)),
    //             };

    //         }

    //     });

    //     newSubArray = _.compact(newSubArray)

    //     setnewSubArray(_.orderBy(newSubArray, ['locality'], ['asc']));

    //     console.log(">>>>>>>subs", newSubArray.filter((u) => u.state === 0))


    // }, []);



    // React.useEffect(() => {

    //     if (packageId) {
    //         packageSelect(packageId);
    //     }

    // }, [packageId]);

    React.useEffect(() => {
        listDistricts();
        getCurrentUser();

    }, []);

    React.useEffect(() => {
        if (homeDistrictId !== 0) {
            listHomecities();
        }
    }, [homeDistrictId]);

    const listDistricts = () => {
        getDistricts()
            .then((res) => {
                console.log("districts>>>", res);
                setallHDistricts(res.data);
                // setallODistricts(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };
    const listHomecities = () => {
        getCities(homeDistrict)
            .then((res) => {
                console.log("cities>>>", res.data.records);
                setallHCities(res.data.records);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setallHCities([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };

    const handleChangeHdist = (e, value) => {
        if (value !== null) {
            sethomeDistrict(value.name_en);
            seterrhomeDistrict(false);
            sethomeDistrictId(value.id);

        } else {
            sethomeDistrict("");
            sethomeDistrictId(1);
        }

        console.log("state>>", value);
        // setnewSubs(newSubArray.filter((u) => u.state === value.id))

        setnewSubs([])

        const filtered = newSubArray.filter((u) => u.state === value.id)

        const perChunk = 700

        const result = filtered.reduce((filtered, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)

            if (!filtered[chunkIndex]) {
                filtered[chunkIndex] = [] // start a new chunk
            }

            filtered[chunkIndex].push(item)

            return filtered
        }, [])

        console.log("<<<<<<<", result)

        for (let i = 0; i < result.length - 1; i++) {

            let item = result[i];

            setTimeout(() => {
                setnewSubs(existing => [...existing, ...item]);
            }, i === 0 ? 0 : (2000));
        }


    };
    const handleChangeHcity = (e, value) => {
        if (value !== null) {
            // console.log(">><<", value.fields.scc_name)
            sethomeCity(value);
            // sethomepostCode(value.postcode);
            // sethomeCity(value.fields.scc_name);
            // sethomepostCode(value.postcode);
            seterrhomeCity(false);
            // seterrhomepostCode(false);
        } else {
            sethomeCity("");
            // sethomepostCode("");

        }

        console.log("city>>", value);
    };


    const saveJob = async (e) => {

        e.preventDefault();


        if (images.length === 0) {
            seterrImages(true);
        }

        console.log(">>>>>>>>", _.filter(Keywords.split(" ")))

        console.log(">>>>>>", images)

        let obj = {
            image: images[0]

        }



        console.log("obj>>>>", obj)


        if (
            images.length > 0

        ) {

            // if (!selectedJob) {

            //     setlOpen(true);
            //     postJobs(obj)
            //         .then((res) => {
            //             setlOpen(false);
            //             if (res.data.includes('stripe')) {
            //                 window.location.href = res.data;
            //                 return;
            //             }
            //             // setlOpen(false);

            //             setbOpen(true);
            //             setbMsg("Job successfully added");
            //             setbStatus("success");

            //             // addToast("Job successfully added", "success");
            //             settitle("");
            //             setjobType("");
            //             setcountry("");
            //             setdescription("");
            //             sethomeDistrict("");
            //             sethomeCity("");
            //             setduration("");
            //             setrate("");
            //             setImages([]);
            //             setKeywords("");
            //             setexpire("");
            //             setcurrency("");
            //             setEditorData("<br/><br/><br/><br/><br/>");
            //             // setOpenToast(true);
            //             // setColor(COLORS.Primary);
            //             // setAlertClass("success");
            //             // setMsg("Successfully updated");
            //             // getCurrentUser();
            //             // seteDisable(true);
            //             // getUser();

            //             // history.push("/");
            //             // window.location.reload();
            //         })
            //         .catch((error) => {
            //             setlOpen(false);
            //             console.log("Error", error.response);
            //             setbOpen(true);
            //             setbMsg(error.response.data.message);
            //             setbStatus("warning");

            //             // addToast(error.response.data.message, "warning");
            //             // setOpenToast(true);
            //             // setColor(COLORS.Orange);
            //             // setAlertClass("error");
            //             // setMsg(error.response.data.message);
            //         })
            // } else {
            //     setlOpen(true);
            //     UpdateJobs(obj, selectedJob?.id)
            //         .then((res) => {
            //             setlOpen(false);
            //             if (res.data.includes('stripe')) {
            //                 window.location.href = res.data;
            //                 return;
            //             } else {

            //                 setbOpen(true);
            //                 setbMsg("Job successfully updated ");
            //                 setbStatus("success");

            //             }


            //             // addToast("Job successfully updated ", "success");
            //             settitle("");
            //             setjobType("");
            //             setcountry("");
            //             setdescription("");
            //             sethomeDistrict("");
            //             sethomeCity("");
            //             setduration("");
            //             setrate("");
            //             setImages([]);
            //             setKeywords("");
            //             setexpire("");
            //             setcurrency("");
            //             onCloseModalQuickView();
            //             // setOpenToast(true);
            //             // setColor(COLORS.Primary);
            //             // setAlertClass("success");
            //             // setMsg("Successfully updated");
            //             // getCurrentUser();
            //             // seteDisable(true);
            //             // getUser();

            //             // history.push("/");
            //             // window.location.reload();
            //         })
            //         .catch((error) => {
            //             setlOpen(false);
            //             console.log("Error", error.response);
            //             setbOpen(true);
            //             setbMsg(error.response.data.message);
            //             setbStatus("warning");

            //             // addToast(error.response.data.message, "warning");
            //             // setOpenToast(true);
            //             // setColor(COLORS.Orange);
            //             // setAlertClass("error");
            //             // setMsg(error.response.data.message);
            //         })

            // }

            postBanner(obj)
                .then((res) => {
                    setlOpen(false);


                    // setbOpen(true);
                    // setbMsg("Logo successfully uploaded");
                    // setbStatus("success");

                    onCloseModalQuickView();
                })
                .catch((error) => {
                    setlOpen(false);
                    console.log("Error", error.response);
                    setbOpen(true);
                    setbMsg(error.response.data.message);
                    setbStatus("warning");

                    // addToast(error.response.data.message, "warning");
                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                })

        } else {
            // handleScroll();
            setbOpen(true);
            setbMsg("Required fields are missing and add missing fields.");
            setbStatus("warning");
        }


    }


    const getCurrentUser = () => {
        CurrentUser()
            .then((res) => {
                if (res.statusCode === 200) {
                    const cData = res.data[0];
                    // setUser(cData);
                    const obj = {
                        id: cData.id,
                        email: cData?.email,
                        firstName: cData?.firstName,
                        lastName: cData?.lastName,
                        phone: cData?.mobileNumber,
                    };
                    //   this.setState({
                    //     firstName: cData?.firstName,
                    //     lastName: cData?.lastName,
                    //     email: cData?.email,
                    //     tele: cData?.phone,
                    //   });
                    console.log("<><><><>", cData);
                    setcurrUser(cData);

                    // setpackageId(cData.packageId ? cData.packageId : "");

                    // if (!selectedJob) {

                    //     if (cData.packageId === "1") {

                    //         let day = moment().add(7, "days")
                    //         day = moment(day).format("YYYY-MM-DD")

                    //         console.log(">>>>>>>", moment(day).toDate())
                    //         setexpire(moment(day).toDate());

                    //     } if (cData.packageId === "2") {

                    //         let day = moment().add(28, "days")
                    //         day = moment(day).format("YYYY-MM-DD")

                    //         console.log(">>>>>>>", moment(day).toDate())
                    //         setexpire(moment(day).toDate());

                    //     } else if (cData.packageId === "3") {

                    //         let day = moment().add(84, "days")
                    //         day = moment(day).format("YYYY-MM-DD")

                    //         console.log(">>>>>>>", moment(day).toDate())
                    //         setexpire(moment(day).toDate());

                    //     }
                    // }

                    localStorage.setItem(
                        `${window.location.hostname}.profile`,
                        JSON.stringify(obj)
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);
                console.log("current user error", error.response.data.message);
            });
    };


    function CustomInput({ onFocus, value, onChange }) {
        return (
            <div className="mt-1.5 flex">
                <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-xl las la-calendar"></i>
                </span>
                <Input
                    readOnly
                    className="!rounded-l-none"
                    placeholder="DD-MM-YYYY"
                    onFocus={onFocus}
                    value={value}
                    onChange={onChange}

                />

            </div>
        )
    }

    useEffect(() => {
        if (bOpen === true) {
            setTimeout(() => {

                setbOpen(false)

            }, 10000);
        }
    }, [bOpen])

    return (
        <>
            <LoadingSpinner open={lOpen} />
            <div className="justify-center w-full mt-2">

                <span className="block text-xl font-bold flex justify-center w-full text-center">

                    {/* {selectedJob ? "Edit Job" : "Post Jobs"} */}
                    Change Banner
                </span>


                {
                    !selectedJob &&

                    <div className="flex justify-center">
                        <span className="text-xs font-normal flex justify-center w-full md:w-9/12 lg:w-[45%] text-center mt-1 mb-6">

                            {/* Are you looking to hire talent for your company? You can post your
                            job vacancies through this page. */}

                        </span>
                    </div>
                }




            </div>
            <div className="w-full flex justify-center px-3">

                <div className="w-full md:w-11/12 lg:w-10/12">

                    <div className="w-full mt-6 space-y-4">

                        <div className="w-full mt-3 xl:mt-6 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                            <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                {images?.map((faker, fakerKey) => (
                                    <>
                                        <div
                                            // key={fakerKey}
                                            className="col-span-12 h-28 flex image-fit cursor-pointer zoom-in justify-center"
                                        >
                                            <img
                                                className="rounded-md"
                                                alt="Midone - HTML Admin Template"
                                                src={faker.includes('/uploads/') === true ? IMAGE_SERVER_URL + faker : faker}
                                            />
                                            {/* <div className="flex flex-col">
                                                <img className="cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                                                <span className="text-xs flex justify-center text-center">{faker?.name}</span>
                                            </div> */}

                                            {/* <div
                                                className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                                            >
                                                <div
                                                    onClick={(e) => {

                                                        // var array = [...images]
                                                        // array.splice(fakerKey, 1);
                                                        // setImages(array)

                                                        setImages([])

                                                    }}
                                                >
                                                  
                                                    <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                </div>
                                            </div> */}


                                        </div>
                                        {/* <div className="form-check">
                                            <input id="checkbox-switch-" className="form-check-input w-5 h-5 bg-gray-200 relative rounded-full text-white right-10 top-[80px]" type="checkbox"
                                                checked={faker?.thumbnail}

                                                onChange={(e) => {
                                                    var array = [...images]
                                                    const index = _.map(array, "thumbnail").indexOf(true);

                                                    // console.log("index>>>", index)

                                                    if (index < 0) {
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    } else {
                                                        array[index].thumbnail = false;
                                                        array[fakerKey].thumbnail = e.target.checked;
                                                        setImages(array)
                                                    }
                                                    // array.filter(person => person.thumbnail === true)
                                                    // array[fakerKey].thumbnail = e.target.checked;
                                                    // setImages(array)
                                                    // console.log("<<<<<",e.target.checked)
                                                    // setlatest(e.target.checked)
                                                    // onChangeUserArr(e, arr.privilege)
                                                    // this.onChangeAllArr(e, arr.privilege);
                                                }}
                                            />
                                           


                                        </div> */}
                                    </>

                                ))}
                            </div>
                            <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative text-sm mt-4">
                                {/* <Lucide icon="Image" className="w-4 h-4 mr-2" /> */}
                                <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                <span className="text-primary mr-1">
                                    Upload image
                                </span>{" "}
                                or drag and drop
                                <input
                                    id="horizontal-form-1"
                                    type="file"
                                    multiple
                                    accept={"image/png, image/jpeg"}
                                    className="w-full h-full top-0 left-0 absolute opacity-0"
                                    onChange={(e) => {

                                        let file = e.target.files[0];

                                        let reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        let fileInp = e.target.files[0];
                                        let baseURL;
                                        // on reader load somthing...
                                        reader.onload = () => {
                                            // Make a fileInfo Object
                                            baseURL = reader.result;
                                            let obj =
                                            {
                                                path: baseURL,
                                                thumbnail: false,
                                            }
                                                ;

                                            // setImages(images.concat(obj))
                                            setImages([baseURL])


                                        };
                                    }}
                                />
                            </div>
                        </div>

                        {
                            // (selectedJob.packageExpire === 0 || !selectedJob) &&

                            <div className="w-full items-center justify-center">

                                <BotToast open={bOpen} msg={bMsg} status={bStatus} />

                                <button
                                    className={
                                        `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#024022]
        w-full h-9 text-slate-50 font-medium mt-4 `}
                                    onClick={saveJob}

                                >
                                    {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                    <span className="block  text-sm font-medium "
                                    >
                                        Save
                                    </span>

                                </button>


                            </div>
                        }


                    </div>

                </div>
            </div>

        </>
    )

}

export default Jobs;