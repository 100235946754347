import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, useHistory } from "react-router-dom";

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name: string;
  desc?: string;
  item?: any;
  type?: any;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
  item,
  type
}) => {
  const history = useHistory();

  return (
    <>
    <Link
      to={"/shop"}
      className={`nc-CardCategory2 ${className}`}
      data-nc-id="CardCategory2"
    >
      <div
        // className={`flex-1 relative w-full h-0 rounded-2xl overflow-hidden group ${ratioClass} `}
        className={`flex-1 relative`}
        onClick={(e) => {

          if (type === "cat") {

            localStorage.setItem("sMain", item.mainId);

          } else if (type === "sub") {

            localStorage.setItem("sMain", item.mainId);
            localStorage.setItem("sSub", item.subcatId);
          }

          // history.push('/shop');
          // window.location.reload();



        }}
      >
       <div className="pt-14">
          <img
            className="object-cover "
            src={featuredImage}

          />
          {/* <NcImage
            containerClassName="w-full h-full flex justify-center"
            src={featuredImage}
            className="object-cover rounded-2xl"
          /> */}
        </div>
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-5 flex-1 text-center">
        <h2 className="text-sm sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-xs text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div>
    </Link>
    </>
  );
};

export default CardCategory2;
