import React, { FC, useEffect, useId, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory2 from "components/CardCategories/CardCategory2New";
import department1Png from "images/brands/logo-1.png";
import department2Png from "images/brands/logo-2.png";
import department3Png from "images/brands/logo-3.png";
import department4Png from "images/brands/logo-4.png";
import department5Png from "images/brands/logo-5.png";
import department6Png from "images/brands/logo-6.png";
import department7Png from "images/brands/logo-7.png";
import { Link } from "react-router-dom";
import { getBrand } from "Services/API/Get";
import { IMAGE_SERVER_URL } from "Constant/System";

export interface CardCategoryData {
  name: string;
  desc: string;
  img: string;
  color?: string;
}
const CATS: CardCategoryData[] = [
  {
    name: "Travel Kits",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100",
  },
  {
    name: "Beauty Products",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100",
  },
  {
    name: "Sport Kits",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department5Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department6Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department7Png,
    color: "bg-orange-100",
  },
];
export interface SectionSliderCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
}

const SectionSliderCategories: FC<SectionSliderCategoriesProps> = ({
  heading = "Shop by department",
  subHeading = "",
  className = "",
  itemClassName = "",
  data = CATS,
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  const [slides, setSlides] = useState<any>([])

  useEffect(() => {
    if (slides.length > 0) {
      const OPTIONS: Glide.Options = {
        perView: 6,
        gap: 32,
        autoplay: 3000,
        hoverpause: false,
        type: 'carousel',
        bound: true,
        breakpoints: {
          1280: {
            perView: 6,
          },
          1024: {
            gap: 20,
            perView: 6,
          },
          768: {
            gap: 20,
            perView: 3,
          },
          640: {
            gap: 20,
            perView: 3,
          },
          500: {
            // gap: 20,
            perView: 2,
          },
        },
      };

      let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
      slider.mount();
      // @ts-ignore
      return () => slider.destroy();
    }

  }, [UNIQUE_CLASS, slides]);

  React.useEffect(() => {
    getSlides();
  }, []);

  const getSlides = () => {
    // setlopen(true);
    getBrand()
      .then((res) => {
        console.log("brand res>>>>", res.data)
        // setlopen(false);


        setSlides(res.data);
      })
      .catch((error) => {
        console.log("brand error", error.response)
        setSlides([])
        // setlopen(false);

      });


  };

  return (
    <div className={`nc-SectionSliderCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading desc={subHeading} hasNextPrev isCenter={true} heading={"Cleaning Brands"} getSlides={getSlides}>
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {slides.map((item: any, index: any) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <CardCategory2
                  // featuredImage={item.img}
                  featuredImage={`${IMAGE_SERVER_URL}${item.image}`}
                  // name={item.name}
                  // desc={item.desc}
                  bgClass={item.color}
                />
              </li>
            ))}
            {/* <li className={`glide__slide ${itemClassName}`}>
              <div
                className={`flex-1 relative w-full h-0 rounded-2xl overflow-hidden group aspect-w-1 aspect-h-1 bg-slate-100`}
              >
                <div>
                  <div className="absolute inset-y-6 inset-x-10 flex flex-col sm:items-center justify-center">
                    <div className="flex relative text-slate-900">
                      <span className="text-base font-semibold ">
                        More collections
                      </span>
                      <svg
                        className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M12 20.4999V3.66992"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <span className="text-xs mt-1 text-slate-800">
                      Show me more
                    </span>
                  </div>
                </div>
                <Link
                  to={"/"}
                  className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"
                ></Link>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderCategories;
