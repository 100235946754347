import rightImg from "images/hero-right1.png";
import rightImg1 from "images/service/1.jpg";
import rightImg2 from "images/service/2.jpg";
import rightImg3 from "images/service/3.jpg";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Floor Scrubbers || Ecomfixerr</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      {/* <BgGlassmorphism /> */}

      <div className="container py-16 lg:py-28 space-y-8 lg:space-y-14 ">
        {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}


        <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-4 gap-10 ">

          <div className="col-span-3 ...">

            <h2 className="text-lg font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
              FLOOR SCRUBBER SERVICE AND REPAIRS
            </h2>
            <div className="text-justify">
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-6">
                With a dedicated team of service technicians who will go always go the extra mile for our customers. We are Melbourne’s leading floor scrubber repair specialists.
              </span>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Wherever you are located in Melbourne, we offer completely affordable tailored and flexible service options that ensures that your commercial floor scrubber continues to operate in peak condition.
              </span>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                We provide premium quality onsite service to hotels, hospitals, institutions and many other sectors. Ecomfixerr delivers preventative maintenance service programs and professional repairs for many well-known brands of floor scrubbers.
              </span>
            </div>
          </div>
          <div className="flex-grow w-full">
            <img className="w-full ml-9" src={rightImg1} alt="" />
          </div>
        </div>



        <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-4 gap-10 ">

          <div className="col-span-3 ...">

            <h2 className="text-base font-semibold text-neutral-900 md:text-xl xl:text-xl dark:text-neutral-100">
              Get Your Floor Scrubber Working Like New Again
            </h2>
            <div className="text-justify">
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-6">
                With quick service turnaround times, our professional service technicians will restore your floor scrubber back to its original glory! We 100 percent guarantee you’ll be delighted with us. that All of our floor scrubbers for repair are only handled by our senior team of professional service technicians who have extensive hands-on experience in repairing all brands of scrubber.
              </span>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                Our low-cost refurbishment process starts with a comprehensive check of all of the main parts of your scrubber including batteries, hoses, gaskets, brushes, pad drivers, and motor/s and we replace these parts as required. You need your machinery working at optimum levels to ensure that you achieve the results you need. We’ll do what it takes to make you (and your site) look good!
              </span>
              <span className="block text-sm xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                With a strong local reputation for outstanding customer service, we take great pride in our service work. We are confident that you’ll love our level of service and workmanship in helping your floor scrubber to work as good as new. Just some of the brands that we support include Tennant, Nilfisk, Alto, Clark Equipment, Hako, and many many more.
              </span>
            </div>
          </div>
          <div className="flex-grow">
            <img className="w-full ml-9" src={rightImg2} alt="" />
          </div>
        </div>





        {/* <SectionFounder /> */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div> */}

        {/* <SectionStatistic /> */}

        {/* <SectionPromo3 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
