import Label from "components/Label/Label";
import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { upperFirst, lowerCase } from 'lodash';
import { EditUserQuery } from "Services/API/Put";
import { CurrentUser, getDistricts } from "Services/API/Get";
import { addToast } from "shared/Toast";
import LoadingSpinner from "shared/LoadingSpinner";

const AccountPass = () => {

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [premail, setpremail] = useState("");
  const [lopen, setlopen] = React.useState(false);

  const [homeNo, sethomeNo] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [homeDistrict, sethomeDistrict] = useState("");
  const [homeCity, sethomeCity] = useState("");
  const [homepostCode, sethomepostCode] = useState("");

  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  const [errhomeAddress, seterrhomeAddress] = useState(false);

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [repassword, setrePassword] = useState("");
  const [idisable, setidisable] = useState(true);
  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);

  const [errPass, seterrPass] = useState(false);
  const [errPass2, seterrPass2] = useState("");
  const [errRePass, seterrRePass] = useState("");

  const [userId, setuserId] = useState("");
  const [currUser, setcurrUser] = useState<any>("");
  const [eDisable, seteDisable] = useState(true);
  const [eDisable2, seteDisable2] = useState(true);

  React.useEffect(() => {
    getCurrentUser();


  }, []);

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber,
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          console.log("<><><><>", cData);
          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);
          setfirstName(cData?.firstName);
          setlasttName(cData?.lastName);
          setemail(cData?.email);
          setpremail(cData?.email);
          setmobile(cData?.mobileNumber);
          sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
          sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
          sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
          sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
          sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
          setuserId(cData?.id);

          getDistricts()
            .then((res) => {

              var mobile22 = res.data?.filter(function (el: any) {
                return el.name_en === cData?.homeDistrict;
              });

              sethomeDistrictId(mobile22.length > 0 ? mobile22[0]?.id : 0)

              // setallODistricts(res.data);
            })
            .catch((error) => {
              console.log("Error", error.response);
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });



          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  const changePassword = async (e?: any) => {

    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    e.preventDefault();

    if (password === "") {
      seterrPass(true)

    }

    if (password != password2) {
      seterrPass2("Password Mismatch")

    }

    if (repassword === "") {
      seterrRePass("This Field is Requied");

    }

    if (password !== "") {
      //   seterrPass(true)
      if (repassword === "") {
        seterrRePass("This Field is Requied");
      } else {
        seterrRePass("");
      }
    }





    // if(password != "" && repassword != "" && !(password === repassword)){
    //   seterrRePass("Password Mismatch")
    // }
    // if(password != "" && repassword != "" && (password === repassword)){
    //   seterrRePass("")
    // }

    let obj = {
      firstName: upperFirst(lowerCase(currUser.firstName)),
      lastName: upperFirst(lowerCase(currUser.lastName)),
      mobileNumber: currUser.mobileNumber,
      email: currUser.email,
      homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
      homeAddress: currUser.homeAddress === null ? "" : currUser.homeAddress,
      homeCity: currUser.homeCity === null ? "" : currUser.homeCity,
      password: password,
      oldPassword: repassword,
      homeDistrict: currUser.homeDistrict === null ? "" : currUser.homeDistrict,
      homepostCode: currUser.homepostCode === null ? "" : currUser.homepostCode,
      note: currUser.note === null ? "" : currUser.note,

    };
    console.log("obj>>>>", obj);

    if (

      (password !== "" && repassword !== "" && password === password2)


    ) {
      setlopen(true);

      EditUserQuery(obj)
        .then((res) => {
          setlopen(false);
          addToast("Update Successful", "success");
          setPassword("");
          setPassword2("");
          setrePassword("");
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Update Successful");
          getCurrentUser();
          // seteDisable(true);

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          addToast(error.response.data.message, "warning");
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        })


    } else {
      // scrollElement();
    }
  };


  return (
    <div>
       <LoadingSpinner open={lopen} />
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-xl sm:text-2xl font-semibold">
            Update your password
          </h2>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Old password</Label>
              <Input type="password" className="mt-1.5"
                placeholder="Enter Old Password"
                onChange={(e) => {
                  setrePassword(e.target.value);
                  seterrRePass("");
                }}
                value={repassword}
              />
              {errRePass && (
                <div className="text-red-600 text-xs text-right mt-2">
                  {errRePass}
                </div>
              )}
            </div>
            <div>
              <Label>New password</Label>
              <Input type="password" className="mt-1.5"
                placeholder="Enter New Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  seterrPass(false);
                }}
                value={password}
              />
              {errPass && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field is Requied
                </div>
              )}
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input type="password" className="mt-1.5"
                onChange={(e) => {
                  setPassword2(e.target.value);
                  seterrPass2("");
                }}
                placeholder="Confirm New Password"
                value={password2}
              />
              {errPass2 && (
                <div className="text-red-600 text-xs text-right mt-2">
                  {errPass2}
                </div>
              )}
            </div>
            <div className="pt-2">
              <ButtonPrimary onClick={changePassword}>Update password</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
