import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useHistory, useLocation, } from "react-router-dom";
import { RegisterUser, signUpVerification } from "Services/API/Post";
import { upperFirst, lowerCase } from 'lodash';
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import { getSignUpVerification } from "Services/API/Get";
import { addToast } from "shared/Toast";
import LoadingSpinner from "shared/LoadingSpinner";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {

  const history = useHistory();
  const [lopen, setlopen] = React.useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const [username, setusername] = useState("");
  const [lpass, setlpass] = useState("");
  const [aKey, setaKey] = useState("login");

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errUsername, seterrerrUsername] = useState("");
  const [errLpass, seterrerrLpass] = useState("");

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  React.useEffect(() => {

    getVerification();

  }, []);

  const getVerification = async (e?: any) => {

    let token = query.get("token");
    if (token !== null) {
      setaKey("register")
      setlopen(true);
      // setProgress(true);
      getSignUpVerification(token)
        .then((res) => {
          RegisterUser(JSON.parse(res.data[0].data))
            .then((res) => {
              setlopen(false);
              addToast(`Registration Successful `, "success");
              history.push("/login");
              setaKey("login")
              // setuserId(res.data[0]);
              // setOpenToast(true);
              // setColor(COLORS.Primary);
              // setAlertClass("success");
              // setMsg("Register Successful");
              // setTimeout(() => {
              //   setlopen(false);
              //   //  setOpen(true);
              //   history.push("/login-register");
              //   // window.location.reload();
              //   // setProgress(false);
              // }, 2000);

              // history.push("/");
              // window.location.reload();
            })
            .catch((error) => {
              setlopen(false);
              addToast(error.response.data.message, "warning");

              // console.log("Error", error.response);
              // setProgress(false);
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          addToast(error.response.data.message, "warning");
          // setProgress(false);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    }
  };


  const sendVerification = async (e: any) => {

    e.preventDefault();
    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (firstName === "") {
      seterrfirstName(true);
    }
    if (lastName === "") {
      seterrlasttName(true);
    }
    if (email === "") {
      seterremail(true);
    }

    if (mobile === "") {
      seterrmobile("This Field is Requied");
    }
    if (password === "") {
      seterrPass(true);
    }
    // if (repassword === "") {
    //   seterrRePass("This Field is Requied");
    // }
    // if (password != "" && repassword != "" && !(password === repassword)) {
    //   seterrRePass("Password Mismatch");
    // }
    // if (password != "" && repassword != "" && password === repassword) {
    //   seterrRePass("");
    // }


    let obj = {
      data: {

        firstName: upperFirst(lowerCase(firstName)),
        lastName: upperFirst(lowerCase(lastName)),
        mobileNumber: mobile,
        email: email,
        password: password,
        clientType: "individual",
        registered: 1
      }

    };

    console.log("obj>>>", obj);
    if (!phoneRegex.test(mobile)) {
      if (mobile === "") {
        seterrmobile("This Field is Requied");
      } else {
        seterrmobile("Please Enter Valid number");
      }
    }



    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      mobile !== "" &&
      password !== "" &&
      // repassword !== "" &&
      // password === repassword &&
      phoneRegex.test(mobile)

      // phoneRegex.test(officeNo)
    ) {
      setlopen(true);
      signUpVerification(obj)
        // RegisterUser(obj.data)
        .then((res) => {
          setlopen(false);
          addToast(`The account activation link has been sent to ${email}. Please click on the confirmation link to activate your account. `, "success");

          // setlopen(false);
          // setuserId(res.data[0]);
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg(
          //   `The account activation link has been sent to ${email}. Please click on the confirmation link to activate your account. `
          // );
          // setTimeout(() => {
          //   //  setOpen(true);
          //   history.push("/login");
          //   window.location.reload();
          // }, 2000);

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          addToast(error.response.data.message, "warning");
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else {
      // scrollElement();
    }
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Ecomfixerr</title>
      </Helmet>
      <LoadingSpinner open={lopen} />
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-2xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-xs font-medium text-neutral-700 dark:text-neutral-300 sm:text-xs">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-xs bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={sendVerification} >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                First Name
              </span>
              <Input
                type="text"
                placeholder="First Name"
                className="mt-1"
                onChange={(e) => {
                  setfirstName(e.target.value);
                  seterrfirstName(false);
                }}
                value={firstName}
              />
              {errfirstName && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field is Required
                </div>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Last Name
              </span>
              <Input
                type="text"
                className="mt-1"
                placeholder="Last Name"
                onChange={(e) => {
                  setlasttName(e.target.value);
                  seterrlasttName(false);
                }}
                value={lastName}
              />
              {errlastName && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field is Required
                </div>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email
              </span>
              <Input
                type="email"
                className="mt-1"
                placeholder="Email"
                onChange={(e) => {
                  setemail(e.target.value);
                  seterremail(false);
                }}
                value={email}
              />
              {erremail && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field is Required
                </div>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Phone Number
              </span>
              <Input
                type="text"
                className="mt-1"
                placeholder="Phone Number"
                onChange={(e) => {
                  setmobile(e.target.value);
                  seterrmobile("");
                }}
                value={mobile}
              />
              {errmobile && (
                <div className="text-red-600 text-xs text-right mt-2">
                  {errmobile}
                </div>
              )}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password" className="mt-1"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  seterrPass(false);
                }}
                value={password}
              />
              {errPass && (
                <div className="text-red-600 text-xs text-right mt-2">
                  This Field is Requied
                </div>
              )}
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link className="text-green-600" to="/login">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
