import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logoFooter.png";
import logoLightImg from "images/logo-light.svg";
import { getLogo } from "Services/API/Get";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV } from "Constant/System";
import ModalQuickView from "components/ModalJob";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  type?: any;
  sideMenu?: any;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "flex-shrink-0",
  type = "",
  sideMenu = false
}) => {
  let proId: any = undefined

  const [logo, setLogo] = React.useState("");

  const [imgSrc, setImgSrc] = useState<any>('')
  const [imgcover, setimgcover] = useState<any>("");

  const [showModalQuickView, setShowModalQuickView] = useState(false);
  const [selectedJob, setselectedJob] = useState("");



  const logoGet = () => {
    getLogo()
      .then((res) => {
        console.log(res.data);
        setLogo(res.data[res.data.length - 1].image)
        // this.setState({
        //   logo: res.data[res.data.length - 1].image,
        // });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  React.useEffect(() => {
    logoGet();


  }, [showModalQuickView]);
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-slate-600 ${className}`}
    >
      <>
        {sideMenu ?
          <>

            {/* THIS USE FOR MY CLIENT */}
            {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
            { }
            {img ? (
              <img
                className={`block ${imgLight ? "dark:hidden" : ""
                  } ${type === "footer" ? "max-h-16 sm:max-h-20" : "max-h-8 sm:max-h-10"}`}
                src={type === "footer" ? img : `${IMAGE_SERVER_URL_DEV}${logo}`}
                // src={img}
                alt="Logo"
              />
            ) : (
              "Logo Here"
            )}
            {imgLight && (
              <img
                className="hidden max-h-8 sm:max-h-10 dark:block"
                // src={imgLight}
                src={`${IMAGE_SERVER_URL_DEV}${logo}`}

                alt="Logo-Light"
              />
            )}

            {/* </Link> */}


          </>
          :
          // <a href="/" className={`relative flex ttnc-logo inline-block text-slate-600 ${className}`}>
          <div className="flex-shrink-0 flex justify-center w-full">
            <div className={`relative w-full overflow-hidden flex ttnc-logo text-slate-600 ${className}`}>

              {/* THIS USE FOR MY CLIENT */}
              {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
              { }
              {img ? (

                <>
                  <img
                    className={`${imgLight ? "dark:hidden" : ""
                      } ${type === "footer" ? "max-h-16 sm:max-h-20" : "max-h-8 sm:max-h-10"}`}
                    src={type === "footer" ? img : `${IMAGE_SERVER_URL}${logo}`}
                    // src={img}
                    alt="Logo"
                  />

                  {
                    false &&

                    <div className={`absolute z-[1] inset-0   ${logo && proId === undefined ? "hover:bg-black hover:bg-opacity-30" : logo ? "" : "bg-gray-400 bg-opacity-70"}  flex flex-col items-center justify-center text-transparent ${logo && proId === undefined ? "hover:text-neutral-50" : logo ? "text-transparent" : "text-neutral-50"} cursor-pointer`}>
                      <svg
                        className={`${logo ? "" : "text-neutral-50"}`}
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                          stroke="currentColor"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <span className={`mt-1 text-[10.8px] ${logo ? "" : "text-neutral-50"}`}>{logo ? "Change Image" : proId != undefined ? "Cover Image" : "Upload Image"}</span>
                      <span className={`text-[10px] ${logo ? "" : "text-neutral-50"}`}>{logo ? "(547 x 95) px" : proId != undefined ? "" : "(1920 x 500) px"}</span>
                      {
                        // proId === undefined &&

                        // <input
                        //   type="file"
                        //   className="absolute inset-0 opacity-0 cursor-pointer"
                        // // onChange={onSelectFile}


                        // />

                        <div className="absolute inset-0 opacity-0 cursor-pointer"
                          onClick={() => { setShowModalQuickView(true) }}
                        >
                        </div>
                      }

                    </div>
                  }

                </>


              ) : (
                "Logo Here"
              )}
              {imgLight && (
                <img
                  className="hidden max-h-8 sm:max-h-10 dark:block"
                  // src={imgLight}
                  src={`${IMAGE_SERVER_URL_DEV}${logo}`}

                  alt="Logo-Light"
                />
              )}

              {/* </Link> */}
            </div>
          </div>


        }

        <ModalQuickView
          show={showModalQuickView}
          onCloseModalQuickView={() => setShowModalQuickView(false)}
          selectedJob={logo}
        />
      </>
    </Link>

  );
};

export default Logo;
