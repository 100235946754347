import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import { getCompany } from "Services/API/Get";
import LoadingSpinner from "shared/LoadingSpinner";
import { postContacts } from "Services/API/Post";
import { addToast } from "shared/Toast";
import SectionPromo2 from "components/SectionPromoHeader";
import cover from "images/cover/cover-9.png"

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  const [company, setcompany] = useState<any>("");
  const [lopen, setlopen] = useState<any>(false);

  const [firstName, setfirstName] = useState("");
  const [subject, setsubject] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");

  const [errfirstName, seterrfirstName] = useState(false);
  const [errsubject, seterrsubject] = useState(false);
  const [errmessage, seterrmessage] = useState(false);
  const [erremail, seterremail] = useState(false);


  const CompanyGet = () => {
    setlopen(true);
    getCompany()
      .then((res) => {
        setlopen(false);
        console.log(res.data);
        console.log("company res>>>", res.data[res.data.length - 1])
        setcompany(res.data[res.data.length - 1]);
        // setLogo(res.data[res.data.length - 1].image)
        // this.setState({
        //   logo: res.data[res.data.length - 1].image,
        // });
      })
      .catch((error) => {
        setlopen(false);
        console.error(error);
      });
  };

  React.useEffect(() => {
    CompanyGet();

  }, []);

  const contactUs = (event?: any) => {

    event.preventDefault();

    const obj = {
      name: firstName,
      email: email,
      subject: subject,
      message: message,
    };

    console.log("obj", obj);

    if (firstName === "") {
      seterrfirstName(true);
    }
    if (subject === "") {
      seterrsubject(true);
    }
    if (email === "") {
      seterremail(true);
    }

    if (message === "") {
      seterrmessage(true);
    }


    if (
      firstName !== "" &&
      email !== "" &&
      subject !== "" &&
      message !== ""

    ) {
      setlopen(true);
      postContacts(obj)
        .then((res) => {
          setlopen(false);
          console.log("res>>>", res);
          addToast(`Your enquiry has been successfully submitted`, "success");
        })
        .catch((error) => {
          console.error(error);
          setlopen(false);
          addToast(error.response.data.message, "warning");
        });

    }



  };


  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <LoadingSpinner open={lopen} />
      <Helmet>
        <title>Contact || Ecomfixerr</title>
      </Helmet>
      <SectionPromo2 bg={cover} />
      <div className="">
        <h2 className="my-20 flex items-center text-2xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {/* Contact */}
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">

              <div >

                <div className="flex flex-row items-center">
                  <div className="w-6 h-6 mr-2">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADlUlEQVR4nO2YTUgVURTH/5op5WdRZErYpqSUonLTJ7V+lfa5qJ2rItr5fLQpSjOCIAyhhD72GURFm8zCImhnC8U+oLKEvqW0sojeixP/gdvjzcydefPuDOEPLgzv3PO/d94999w7B5ji/6cWQALAbQDDAL6yyXMvgFYAixFhGgD0AUhpNnnRVYgQ0wF0AUhygp8AnAewlatTzCbPjQAusE+KPmcAFIT9ErMA3OGkvgFoA1Cm4Sd92gF8V1anAiGuhBVKowwtrywH8JIa/QAKEQJdnMArAFVZ6Ijva2p1wjANjG8JpxUB6K1kmP3mszGskJI9ERTHqSkp2gi1SnZy2tib+cJyhkzwOebQvxzAGLUXwQAJDiZp1I52h/PDaRUvsU8cBujlYHIm2K2E2H8AaAEwny3O31IOK7ON9lswwBMOZnfNsM4VeYl0WmmTMHMK28cwwAQHK3GxV2awVdI2buNbQrto5BzrNJ5hYx+nXcIpnWravtj4Fiu3hJwzysFqXFJz3CFR2IXWQtrlgMw5/S4bNqZs9lae3FV8iZ8uvltovwsDdHIwSbF2tPlMvx3scxoG2MHBhlz6xRhCE5oHojBMbUnDOadMCZH6AHWXUVO0S2GImxz0aICax6h5AwbZzUGfAsgPQE80nlFzFwxSBOAtB5ZP2mxppNYbahvlEAe/H4DWA2olEAKy6T9zAquz0FmrnPYVCIlTnMTVLDSuU+MkQqSady/57F3jw3+dcrfKdDczivWJ+hBAns+9Iak3dEqZbWRC2z347aTPO81amBH2K+eKTvosYl/x2YcIUQBg0EOYWJfKQRPl0jwAz10K0RvTNm6Sd6U6B9169pG+m9LScNJhrBEfe/Av6zUq6un/6EVl40+zuYpYG1wK3RbS95HGeDInz5yjs2QlXWYDeE+/AxnsB2n7CGCOB90O+smcPFGolP6dwiQTe5WTeoHye43yPb/Ho2adUhT0VOhuouMA/HGF/vcYNvlKmeiaT80Bl3paRnocalM6zOX5IBqHARzh8wcA83xqtlDjsq6D1GAnWRmXK4hfrKrhL7ZsP2GrOadJztGVZpeSjZ+EIe1sAHp91GoOvLMLM1mkGOJztjTr/smel0+DJWxBUK4b9nG+sWz2qNKjk4is01XSb1RpcjsalrLDWBgFAA8U8mZgW1c7QWM3ok835ypXl3+QW+ULjUtb1NpIel1tQwQm5bfJ3KdA1PgDYpWe90NC9SoAAAAASUVORK5CYII=" />


                  </div>
                  <div>
                    <h3 className="uppercase font-semibold text-xs dark:text-neutral-200 tracking-wider ">
                      {"ADDRESS"}
                    </h3>


                  </div>

                </div>

                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">

                  {company?.companyName}<br />

                  {company?.address},{" "}

                  {company?.suburb} , {company?.postCode}
                </span>
              </div>

              <div >
                <div className="flex flex-row items-center">
                  <div className="w-6 h-6 mr-2">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSUlEQVR4nO2YSWsUQRiGH424oV40irl4MKCiBwOCEZeE5Ae4oKLkIOTiRRz1FwRPEU8KKuPNk3rxkkMg4kWjmYj+AAMKuQiuCGFcxmWk4E0oYo9T3T3d093UA80w1V/VV+9Q31IDHo/H42kT9YI8FE5IXqkXVkg3+WNrkJBPwEHyw17gXaNg/w6cIvscBaqNgv2mPn8Dl8guF7VHe8//BHvJMroNLCM7dADXtbc/wEizrHUM+KqxcWAt7WclcN86/qdd028v8F7jz4FNtI/1wKSVkA6FrSMmtb3SuzfADtKnG5jRHl4D26MWRPNrPNH7z0Af6bEP+CDfFWBj3Mq+Argnmx/AEMlzHPgmnw+A1a1qUZYoSyzOGElQsjLnNWBpE/uF/Zuj8ziEk1+aWG5xejZrlbW28XHecV7kXvGIVVUngHXEZw0wpjXNkToZYm5mmt7NwAtt5iOwP+T8TAjZBcxax6knwhoLQqZbfFMzqdKFQeCL1d+ZzxmJiySk0mIhUw7OzyiVG/u7wDbgpb7P5SFGTON3WSncOB9VajesAu5YKf6qY1ZMVcgG4DDwTE5/Amcb2J4DarJ7BHS2S0i/FcBBz6xs/scB4K1lvyeNYA8KblNbLiheqnqmNOZ6LegCnlq1ZTjpYHcJ7qgsB25Yvm5pLFBIHhiyuglTPLfkVYhht+5FdV36BvIqZD77PdTGa2osYwW7a/VOqhZdsWpRrGBPMsBdOaEuIJdHazE7iyKEQgqZjhHg8/+wZOGhEiPAMyXE4/F4PKTNX1LaBGZV5fLZAAAAAElFTkSuQmCC" />


                  </div>
                  <div>
                    <h3 className="uppercase font-semibold text-xs dark:text-neutral-200 tracking-wider ">
                      {"EMAIL"}
                    </h3>


                  </div>

                </div>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  <a href={`mailto:${company?.email}`}>
                    {company?.email}
                  </a>
                </span>
              </div>


              <div >
                <div className="flex flex-row items-center">
                  <div className="w-6 h-6 mr-2">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEbUlEQVR4nO2ZaahVVRTHfy+1njaYmu+9JuUhZL1MP8QrpKwIpC8NkEODFGnhRAWBZQN+0KCE6EMUDdCANlp9SdFGpHpoZiRaOQTlFCVWVqLvCZrdWPDfsNmcYZ9773nd4P7gwD3nrL3WXnuvvfc660KTJk1qoQWYBDwFfAnsA1byP3NgOrAdqATX+py2rcB4YArwEPA08C7QA3wP/KGrL9D7J7AX2Ap8ALwAzAa61J/CjARWewZM+aPAFUBHgrwZugdYpk78neB8rdfv0n9prBOjgB/U+C9gLjAwkBkGTANelJOh0aMa+feAJ+TkNIXo+cBw6WhN0DsKuBC4FrgXeD3BxqfSk8nXEraQ6vSenwjcopk6Fijep9GaB3QDJ1F/xgCPAb96Ni1yUvkK2Ai06d5GbSHwSzDinwAPABMS4negwm26QvJNjeJWryPuOgzsBr4ALopwaCjwudraTEdxA/CjZ9RC7sGUdTJejn0MHKlyHXwWuajvlPyreYIWGq94BjYD1yQYsdFZAHwTdOi4QtN2qiXADG0UFtenJei4ANivtrdGONIt2S1ZQkM0MiZ4SHE/IEV2YbCrPKdZtMVclJnS8zNwao5sh2TN+VSWSWhPRMyukuzDwCBqo0XnU0U7XV7EuLWaykEJjctRdgJwQLK2ZdaDixWWR3MGscWLhNQ15RZquMeHjPNmrp48K70HNOPPaBO5CZgInAWc551zqfwkoXNyDM6VnB1YjnUJO5GlJSFZcq3a+WJ2udeyOrhBQlfnOPKG5OZ7z9IMhuTJteh0v16n+5PaATfqIOxT1nBGVgefl9JHUt7bYXcj0Cu5sQkdTLsvKlcTd0mppSJJKYJ/wr/tvW/P6GDeDLh70+FYrRN8qtaFG8QrgUtiHOmU0oP6fbPSkX88g5Zq3K38yzgT2KZ3Nv2OngwnrJN+WlSRjg4925TR9oh2zVzeT2jcq9P+skDWRmuHlwFkJnIpjNQpbTq2S+fJOnDX6rB1g2sO3heruE1Te0iLf55SiZB2pesVZc0jqJ4R3izs8JLW0hnmjeLmKtOSENPh6zydkjlFabcLhXqOXrs3y+sVYqUwyFtDluKf7b3L25Vin58L7NKzNQlfqDVjB9XLMvCbUgVKcMRt9/u9746qig9pLJHiPuU9ZdOtL0izubheSm+TwmMqDPQX13lVGetDTXR5IzMnQ66eoeUzR+8Pqy9VMRj4TopeypEtyxFk22S+VZ8Kc78UbKlWQZ0Y4tUGrE5Q+LxwO8dV/PdM8nbMvO/6xIKA1WFjKDO0HB9K9g4K8I4a3d5AjsyU7IoCfrBTjUbTOHR6WUU0vZGFiP5ksPdZEY37/6LQwiqZod6ZEo2rqFhJs1HoUp+s8B2N+wSdTOMwWX2yvwGjWaFGtlM0CrPUp7eKNFqqRotoHBapT48XaeT+h1geUSks6+oJbC/Xc5uZaC5XIytA+PSXE5WEg9JVQsNqTiZtBQyUQSXjKlx2+iiiwFYWPSm2Ld9q0qQJxfgXNOM6M+8ioVAAAAAASUVORK5CYII=" />


                  </div>
                  <div>
                    <h3 className="uppercase font-semibold text-xs dark:text-neutral-200 tracking-wider ">
                      {"PHONE"}
                    </h3>


                  </div>

                </div>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  {company?.phoneNumber}
                </span>
              </div>
              {/* {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-xs dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))} */}
              <div>
                {/* <h3 className="uppercase font-semibold text-xs dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3> */}
                {/* <SocialsList className="mt-2" fb={company?.facebook} /> */}
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={contactUs}>
                <label className="block">
                  <Label>Full name</Label>

                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    onChange={(e) => {
                      setfirstName(e.target.value);
                      seterrfirstName(false);
                    }}
                  />
                  {errfirstName && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      This Field is Requied
                    </div>
                  )}
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    onChange={(e) => {
                      setemail(e.target.value);
                      seterremail(false);
                    }}
                  />
                  {erremail && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      This Field is Requied
                    </div>
                  )}
                </label>
                <label className="block">
                  <Label>Subject</Label>

                  <Input
                    type="text"
                    placeholder="subject"
                    className="mt-1"
                    onChange={(e) => {
                      setsubject(e.target.value);
                      seterrsubject(false);
                    }}
                  />
                  {errsubject && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      This Field is Requied
                    </div>
                  )}
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea className="mt-1" rows={6}
                    placeholder="message"
                    onChange={(e) => {
                      setmessage(e.target.value);
                      seterrmessage(false);
                    }}
                  />
                  {errmessage && (
                    <div className="text-red-600 text-xs text-right mt-2">
                      This Field is Requied
                    </div>
                  )}
                </label>
                <div>
                  <ButtonPrimary
                    type="submit"
                  >Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative my-24 lg:my-32 py-24 lg:py-32">
          <BackgroundSection />
          <SectionPromo1 />
        </div>
      </div> */}

      <div className="relative my-0 lg:my-0 pt-24">

        {/* <SectionPromo1 /> */}
        <iframe
          src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=167 Cheltenham Road, Dandenong VIC , 3175&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          className="relative w-full h-[600px] border-none block"
          allowFullScreen

        ></iframe>
      </div>

    </div>
  );
};

export default PageContact;
