import React, { FC, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/rightLargeImg.png";
import rightLargeImgDark from "images/rightLargeImgDark.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import cover from "images/cover/cover-10.png"
import cover2 from "images/cover/cover-11.png"
import { NavLink } from "react-router-dom";
import { getBanner, getLogo } from "Services/API/Get";
import ModalQuickView from "./ModalBanner";
import { IMAGE_SERVER_URL } from "Constant/System";

export interface SectionPromo1Props {
  className?: string;
}

const SectionPromo1: FC<SectionPromo1Props> = ({ className = "" }) => {

  let proId: any = undefined

  const [logo, setLogo] = React.useState("");

  const [imgSrc, setImgSrc] = useState<any>('')
  const [imgcover, setimgcover] = useState<any>("");

  const [showModalQuickView, setShowModalQuickView] = useState(false);
  const [selectedJob, setselectedJob] = useState("");

  const logoGet = () => {
    getBanner()
      .then((res) => {
        console.log(res.data);
        setLogo(res.data[res.data.length - 1].image)
        // this.setState({
        //   logo: res.data[res.data.length - 1].image,
        // });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  React.useEffect(() => {
    logoGet();


  }, [showModalQuickView]);


  return (
    <>

      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        selectedJob={logo}
      />

      <div
        className={`relative flex flex-col lg:flex-row items-center ${className}`}
      >
        <NavLink to={{ pathname: "/shop" }} >
        {/* <img
        className="hidden md:block relative w-full h-full object-cover"
        // src={backgroundLineSvg}
        src={cover}
        alt=""
      />
       <img
        className="block md:hidden sm:relative sm:w-full sm:h-full sm:object-cover"
        // src={backgroundLineSvg}
        src={cover2}
        alt=""
      /> */}

        <div className="absolute inset-0">
          <img
            className="invisible md:visible absolute w-full h-full object-cover dark:opacity-5"
            // src={backgroundLineSvg}
            // src={cover}
            src={`${IMAGE_SERVER_URL}${logo}`}
            alt="backgroundLineSvg"
          />
          <img
            className="visible md:invisible absolute w-full h-full object-cover dark:opacity-5"
            // src={backgroundLineSvg}
            // src={cover2}
            src={`${IMAGE_SERVER_URL}${logo}`}
            alt="backgroundLineSvg"
          />

          {
            false &&

            <div className={`absolute z-[1] inset-0   ${logo && proId === undefined ? "hover:bg-black hover:bg-opacity-30" : logo ? "" : "bg-gray-400 bg-opacity-70"}  flex flex-col items-center justify-center text-transparent ${logo && proId === undefined ? "hover:text-neutral-50" : logo ? "text-transparent" : "text-neutral-50"} cursor-pointer`}>
              <svg
                className={`${logo ? "" : "text-neutral-50"}`}
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                  stroke="currentColor"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className={`mt-1 text-[10.8px] ${logo ? "" : "text-neutral-50"}`}>{logo ? "Change Image" : proId != undefined ? "Cover Image" : "Upload Image"}</span>
              <span className={`text-[10px] ${logo ? "" : "text-neutral-50"}`}>{logo ? "(1920 x 500) px" : proId != undefined ? "" : "(1920 x 500) px"}</span>
              {
                // proId === undefined &&

                // <input
                //   type="file"
                //   className="absolute inset-0 opacity-0 cursor-pointer"
                // // onChange={onSelectFile}


                // />

                <div className="absolute inset-0 opacity-0 cursor-pointer"
                  onClick={() => { setShowModalQuickView(true) }}
                >
                </div>
              }

            </div>
          }


        </div>
        <div className="lg:w-[800px] max-w-lg h-[580px] md:h-[400px] xl:h-[600px] 2xl:h-[600px] relative"></div>



        {/* <div className="relative flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo className="w-28" />
        <h2 className="font-semibold text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl mt-6 sm:mt-10 !leading-[1.2] tracking-tight">
          Earn free money <br /> with Ecomfixerr
        </h2>
        <span className="block mt-6 text-slate-500 dark:text-slate-400 ">
          With Ecomfixerr you will get freeship & savings combo...
        </span>
        <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
          <ButtonPrimary href="/page-collection" className="">
            Savings combo
          </ButtonPrimary>
          <ButtonSecondary
            href="/page-search"
            className="border border-slate-100 dark:border-slate-700"
          >
            Discover more
          </ButtonSecondary>
        </div>
      </div> */}
        {/* <div className="relative flex-1 max-w-xl lg:max-w-none">
        <NcImage containerClassName="block dark:hidden" src={rightImgDemo} />
        <NcImage
          containerClassName="hidden dark:block"
          src={rightLargeImgDark}
        />
        
      </div> */}
        </NavLink>
      </div>
    </>
  );
};

export default SectionPromo1;
