import { IMAGE_SERVER_URL_DEV } from "Constant/System";


export const EMAIL_TAMPLETE = ({ customerName, invoiceNo, pdfName }) => {
  return `
    <html lang='en'>
  
    <head>
        <meta charset='utf-8' />
        <title>${pdfName}</title>
        <meta name='viewport' content='initial-scale=1.0; maximum-scale=1.0; width=device-width;'>
   
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link
            href="https://fonts.googleapis.com/css2?family=Asap+Condensed:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
            rel="stylesheet">
        <style>
            @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300,100);
    
            .txt-primary {
                color: #21949F
            }
    
            .background-primary {
                background-color: #21949F
            }
    
            .background-secondary {
                background-color: #E5DDD8
            }
    
            .background-tertiary {
                background-color: #326764
            }
    
            .txt-secondary {
                color: #326764
            }
    
            .invoice-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
    
            .invoice-details-container {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                flex-direction: column;
            }
    
            .txt-invoice {
                font-family: sans-serif;
                text-transform: uppercase;
                text-align: right;
                font-weight: bold;
                letter-spacing: 2;
            }
    
            .txt-1 {
                font-size: 11pt;
                font-weight: 500;
                letter-spacing: 0.8px;
                font-family: 'Roboto';
            }
    
            .txt-2 {
                font-size: 18pt;
                font-weight: 600;
                font-family: sans-serif;
            }
    
            .txt-3 {
                font-size: 14pt;
                font-weight: 400;
                font-family: sans-serif;
            }
    
            .tb-row-1 {
    
                padding: 6px 0px;
                font-size: 11pt;
                font-weight: 400;
                display: flex;
                align-items: center;
                font-family: 'Roboto';
                letter-spacing: 1;
    
            }
    
            .tb-row-2 {
    
                padding: 8px 0px;
                font-size: 10pt;
                font-weight: 400;
                display: flex;
                align-items: center;
                font-family: 'Roboto';
                letter-spacing: 1;
    
            }
    
            .txt-4 {
                padding: 6px 5px;
                font-size: 14pt;
                font-weight: 400;
                display: flex;
                align-items: center;
                font-family: 'Roboto';
                letter-spacing: 1;
            }
    
            .contact-container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
               
            }
    
            .txt-5 {
                font-size: 17pt;
                font-weight: 600;
                display: flex;
                align-items: center;
                font-family: 'Roboto';
                letter-spacing: 3px;
            }
    
            .txt-6 {
                align-items: center;
                font-family: 'Roboto';
                letter-spacing: 1px;
                font-size: 15pt;
                margin-bottom: 3px;
            }
    
            .txt-7 {
                align-items: center;
                font-family: 'Roboto';
                font-weight: 400;
                letter-spacing: 1px;
                font-size: 13pt;
            }
    
            .custom-hr {
                width: 45%;
                height: 2px;
                margin: 8px 0px 20px;
                opacity: 1;
            }
    
            body {
                background-color: #e5e5e5;
            }
        </style>
    </head>
    
    <body>
        <div class="container" style="background:#fff;max-width: 800px;">
        <div style="padding:80px 10%">
        <div style="display: flex;justify-content: space-between;align-items: center;">
        <div class="col"  style="width:580px;text-align: left;">
            <img src="${IMAGE_SERVER_URL_DEV}/uploads/logo/1670255682167.jpeg" style="width:220px;min-width:220px" alt="logo" />
       <br/>
       <br/>

       <br/>

            </div>
       
       
    </div>
        <div style="margin-bottom:5px;" class="txt-secondary txt-1">
            Hello  <strong>${customerName}</strong>,
        </div>
        <br />
        <div style="margin-bottom:5px;" class="txt-secondary txt-1">
            We’re happy to confirm that we’ve received your order and your order number is <b> ${invoiceNo}</b>. Please
            find the attached invoice and the order summary.
        </div>
        <br />
        <div style="margin-bottom:5px;" class="txt-secondary txt-1">
            If you have any questions, call us on  (03) 9794 0654 weekdays between 9 am – 5 pm.


        </div>
        <br />
        <div style="margin-bottom:5px; text-align: left;" class="txt-secondary txt-1">
            Thank you for ordering from Ecomfixerr.
        </div>
       
    </div>
         
    
            </div>
        </div>
    </body>
    
    </html>`;
};
{
  // <br />
  // <br />
  // <br />
  // <a href="${IMAGE_SERVER_URL}/upload/${pdfName}.pdf" target="_blank" style="display:flex">
  //     <img src="https://icones.pro/wp-content/uploads/2021/03/icone-pdf-symbole-png-rouge.png"
  //         style="width:60px;object-fit: contain;" />
  //     <p style="color:#A10000;font-family: 'Roboto Slab', serif;font-weight: 500;">${pdfName}.pdf</p>
  // </a>
}
