import jwtDecode from "jwt-decode";
import Axios from "../../Axios";

const getToken = localStorage.getItem(`${window.location.hostname}.login`);
let myId = "";
if (getToken) {
  const { data } = JSON.parse(getToken);
  const { id } = jwtDecode(data);
  myId = id;
}

export const EditUserQuery = async (obj) => {
  return await Axios({
    pathname: `api/v1/user/${myId}`,
    obj: obj,
    method: "put",
  });
};
export const ChangePasswordQuery = async (obj) => {
  return await Axios({
    pathname: `api/v1/user/${myId}`,
    obj: obj,
    method: "put",
  });
};

export const EditBillInfo = async (obj, id) =>
  await Axios({
    pathname: `api/v1/billinginformation/${id}`,
    obj: obj,
    method: "put",
  });
export const EditShipInfo = async (obj, id) =>
  await Axios({
    pathname: `api/v1/shippingInformation/${id}`,
    obj: obj,
    method: "put",
  });

export const UpdateCartItems = async (obj, id) =>
  await Axios({
    pathname: `api/v1/cart/${id}`,
    obj: obj,
    method: "put",
  });

  export const UpdateUserwithemail = async (obj) =>
await Axios({
  pathname: `api/v1/user/updateWithEmail/${myId}`,
  obj: obj,
  method: "put",
});

export const setSiteVisits = async () => {
  return await Axios({
    pathname: "api/v1/settings/sitevisits",
    method: "put",
  });
};
