import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard";
import ProductCardNew from "components/ProductCardNew";
import { PRODUCTS } from "data/data";
import SidebarFilters from "./SidebarFilters";
import Pagination from "shared/Pagination/PaginationNew";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Categories, getAllProducts } from "Services/API/Get";
import _ from 'lodash';
import LoadingSpinner from "shared/LoadingSpinner";

export interface PageCollection2Props {
  className?: string;
}


const PageCollection2: FC<PageCollection2Props> = ({ className = "" }) => {

  const [nProducts, setnProducts] = useState([]);
  const [allSizes, setallSizes] = useState<any | null>([]);
  const [allColours, setallColours] = useState<any | null>([]);
  const [allSubs, setallSubs] = useState<any | null>([]);
  const [categories, setcategories] = useState([]);
  const [tags, settags] = useState<any | null>([]);
  const [selectedSize, setselectedSize] = useState("");
  const [selectedColour, setselectedColour] = useState("");
  const [selectedCat, setselectedCat] = useState<any | null>(4);
  const [minPrice, setminPrice] = useState<any | null>(0);
  const [maxPrice, setmaxPrice] = useState<any | null>(999);
  const [selectedSub, setselectedSub] = useState<any>("");

  const [selectedSubCat, setselectedSubCat] = useState([]);
  const [dataCount, setcount] = useState(0);
  const [search, setsearch] = useState("");
  const [lopen, setlopen] = useState(true);

  const [mainDesc, setmainDesc] = useState<any>("");
  const [subDesc, setsubDesc] = useState("");
  const [filterSortValue, setFilterSortValue] = useState("");
  const [filterSortIndex, setfilterSortIndex] = useState(0);

  const [pageLimit, setpageLimit] = useState(68);
  const [currentPage, setCurrentPage] = useState(1);

  const [Scat, setScat] = useState(false);

  const getallCategory = () => {

    Categories()
      .then((response) => {
        console.log("category>>>>", response?.data);
        setcategories(response.data);
        // this.setState(
        //   {
        //     array: res.data.result,
        //     filter: res.data.result,
        //     showPages: m,
        //     showOff: true,
        //   },
        //   async () => {
        //     await this.getPaginationGroup(m);
        //   }
        // );
      })
      .catch(() => {
        // this.setState({
        //   array: [],
        //   filter: [],
        //   showPages: 1,
        //   showOff: false,
        // });
      });

  }

  useEffect(() => {

    getallCategory();
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

  }, [])

  useEffect(() => {

    const fetchData = async () => {
      const data = await getFilters();
      const data2 = await getItemProducts2();
    }


    fetchData();


  }, [selectedCat])

  useEffect(() => {
    if (allSubs.length !== 0) {
      const fetchData = async () => {
        const data2 = await getItemProducts();
      }

      fetchData();
    }

  }, [selectedSub, allSubs, filterSortValue, pageLimit, currentPage, selectedColour, selectedSize, minPrice, maxPrice, search])

  useEffect(() => {
    console.log("sub cats>>>>>", selectedSubCat)

  }, [selectedSubCat])

  const getFilters = async () => {

    const ob = {
      sort: {
        sortField: "productName",
        sort: "asc",
      },
      categories: selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
      distinct: "0",

    };
    // setlopen(true);

    await getAllProducts(ob).then((res) => {

      console.log("FFF>>", res)
      const allColors = _.uniqBy(res?.data?.result, function (e: { clolour: any; }) {
        return e.clolour;
      });
      var allColors2 = allColors?.filter(function (el: any) {
        return el.clolour !== "";
      });
      const allSizes = _.uniqBy(res?.data?.result, function (e: { size: any; }) {
        return e.size;
      });
      var sizeArr = allSizes?.filter(function (el: { size: string; }) {
        return el.size !== "";
      });
      const allPrice = _.uniqBy(res?.data?.result, function (e: { discountedprice: any; }) {
        return e.discountedprice;
      });
      const allTags = _.uniqBy(res?.data?.result, function (e: { tags: any; }) {
        return e.tags;
      });
      const allSubs = _.uniqBy(res?.data?.result, function (e: {
        subcatId: any;
        subCategory: any;
        subcatDesc: any;
      }) {
        return e.subCategory;
      });

      console.log("WWEDSD", allSubs)

      var subsArr = allSubs?.filter(function (el: { subCategory: null; }) {
        return el.subCategory !== null;
      });
      const myPrices = _.map(allPrice, "discountedprice");

      const Pricelist = _.sortBy(myPrices, [
        function (o: any) {
          return o;
        },
      ]);

      const mySizes = _.map(sizeArr, "size");
      const myColors = _.map(allColors2, "clolour");
      const myTags = _.map(allTags, "tags");
      const mySubs = _.map(subsArr, "subCategory");

      const updateMyColors = myColors?.map((item: any, index: any) => {
        return {
          id: index,
          name: item,
          status: false,
        };
      });

      const updateMySubs = subsArr.map((item, index) => {

        return {
          id: item.subcatId,
          name: item.subCategory,
          // description: item.subcatDesc,
        };



      });
      console.log("subs>>>>>", updateMySubs)
      setallSizes(mySizes);
      setallColours(myColors);
      settags(myTags);
      setallSubs(updateMySubs);

      let Id2: any = localStorage.getItem("sSub");
      setselectedSub(Id2 ? parseInt(Id2) : "");
      // this.setState({
      //   color: updateMyColors,
      //   size: mySizes,
      //   min: Pricelist[0],
      //   max: Pricelist[Pricelist.length - 1],
      //   range: {
      //     min: Pricelist[0],
      //     max: Pricelist[Pricelist.length - 1],
      //   },
      // });
    })
      .catch(() => {
        setlopen(false);
      });



  }

  const getItemProducts = async () => {
    let sort = {


      sortField: "productName",
      sort: "asc",

    }
    let latest = ""
    let featured = ""

    if (filterSortValue === "HighToLow") {

      sort = {


        sortField: "unitPrice",
        sort: "desc",

      }
    } else if (filterSortValue === "LowToHigh") {

      sort = {


        sortField: "unitPrice",
        sort: "asc",

      }
    } else if (filterSortValue === "latest") {
      latest = "1"
    } else if (filterSortValue === "featured") {
      featured = "1"
    } else {
      sort = {


        sortField: "productName",
        sort: "asc",

      }
      latest = ""
      featured = ""
    }





    // console.log(sortType)

    const obj = {

      sort: sort,
      categories: selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
      subId: selectedSub === "" ? JSON.stringify([]) : JSON.stringify([selectedSub]),
      search: search,
      latest: latest,
      featured: featured,
      pNo: currentPage.toString(),
      limit: pageLimit.toString(),
      color: selectedColour,
      size: selectedSize,
      pto: maxPrice,
      pfrom: minPrice,

    };
    console.log("><<<<<<<<<<<<", obj);
    try {
      await getAllProducts(obj)
        .then((response) => {
          // setlopen(false);
          console.log(">>>>>>>>>>>>>>>>>>", response?.data);

          let m =
            Math.ceil(parseFloat(response.data.count[0].pages)) === 0
              ? 1
              : Math.ceil(parseFloat(response.data.count[0].pages));

          setnProducts(response.data.result);
          setcount(response.data.count[0].count);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     filter: res.data.result,
          //     showPages: m,
          //     showOff: true,
          //   },
          //   async () => {
          //     await this.getPaginationGroup(m);
          //   }
          // );
        })
        .catch(() => {
          setnProducts([]);
          setcount(0);
          // setlopen(false);
          // this.setState({
          //   array: [],
          //   filter: [],
          //   showPages: 1,
          //   showOff: false,
          // });
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  }

  const getItemProducts2 = async () => {
    let sort = {


      sortField: "productName",
      sort: "asc",

    }
    let latest = ""
    let featured = ""

    if (filterSortValue === "HighToLow") {

      sort = {


        sortField: "unitPrice",
        sort: "desc",

      }
    } else if (filterSortValue === "LowToHigh") {

      sort = {


        sortField: "unitPrice",
        sort: "asc",

      }
    } else if (filterSortValue === "latest") {
      latest = "1"
    } else if (filterSortValue === "featured") {
      featured = "1"
    } else {
      sort = {


        sortField: "productName",
        sort: "asc",

      }
      latest = ""
      featured = ""
    }





    // console.log(sortType)

    const obj = {

      sort: sort,
      categories: selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
      subId: selectedSub === "" ? JSON.stringify([]) : JSON.stringify([selectedSub]),
      search: search,
      latest: latest,
      featured: featured,
      pNo: currentPage.toString(),
      limit: pageLimit.toString(),
      color: selectedColour,
      size: selectedSize,
      pto: maxPrice,
      pfrom: minPrice,

    };
    console.log("><<<<<<<<<<<<", obj);
    try {
      // setlopen(true);
      await getAllProducts(obj)
        .then((response) => {
          setlopen(false);
          console.log(">>>>>>>>>>>>>>>>>>", response?.data);

          let m =
            Math.ceil(parseFloat(response.data.count[0].pages)) === 0
              ? 1
              : Math.ceil(parseFloat(response.data.count[0].pages));

          setnProducts(response.data.result);
          setcount(response.data.count[0].count);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     filter: res.data.result,
          //     showPages: m,
          //     showOff: true,
          //   },
          //   async () => {
          //     await this.getPaginationGroup(m);
          //   }
          // );
        })
        .catch(() => {
          setnProducts([]);
          setcount(0);
          setlopen(false);
          // this.setState({
          //   array: [],
          //   filter: [],
          //   showPages: 1,
          //   showOff: false,
          // });
        });
    } catch (error) {
      console.log("T&C ", error);
    }
  }


  useEffect(() => {
    console.log("selected cat>>>>", selectedCat)
  }, [selectedCat])

  useEffect(() => {
    console.log("param>>>", localStorage.getItem("sMain"));

    let Id: any = localStorage.getItem("sMain");
    let Id2: any = localStorage.getItem("sSub");




    if (((Id && Id !== undefined)
      // || (Id2 && Id2 !== undefined)
    ) && (categories.length !== 0 && nProducts.length !== 0 )) {
      setselectedCat(parseInt(Id));
      // setselectedSub(Id2 ? parseInt(Id2) : "");


      Categories()
        .then((response) => {

          var subsArr = response?.data?.filter(function (el: { mainId: number; }) {

            return el.mainId === parseInt(Id!);
          });

          console.log("MAINNNNNNNNN", subsArr)
          setmainDesc(subsArr[0])
          setScat(true);

        })
        .catch(() => {
          setScat(true);
        });






    }

    // return () => {
    //   localStorage.removeItem("sMain");
    // };

  }, [categories, nProducts])

  useEffect(() => {
    // if (Scat === true) {
    //   localStorage.removeItem("sMain");
    //   localStorage.removeItem("sSub");
    //   setScat(false);
    // }

    // return () => {
    //   localStorage.removeItem("sMain");
    //   localStorage.removeItem("sSub");
    // }

  }, [Scat])


  React.useEffect(() => {

    if (selectedCat !== 4) {

      localStorage.setItem("sMain", selectedCat)
    }




  }, [selectedCat])






  return (
    <div
      className={`nc-PageCollection2 ${className}`}
      data-nc-id="PageCollection2"
    >
      <LoadingSpinner open={lopen} />
      <Helmet>
        <title>Shop || Ecomfixerr</title>
      </Helmet>

      <div className="container py-16 lg:pb-0 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="w-full">
            <h2 className="block text-xl sm:text-2xl lg:text-3xl font-semibold">
              {/* Man collection */}
              {mainDesc?.maincategory}
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-xs sm:text-sm text-left">
              {/* We not only help you design exceptional products, but also make it
              easy for you to share your designs with more like-minded people. */}
              {mainDesc?.description}
            </span>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* LOOP ITEMS */}
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/3 xl:w-1/4 pr-4">
                <SidebarFilters
                  categories={categories}
                  setSelectedcat={setselectedCat}
                  selectedCat={selectedCat}
                  allSubs={allSubs}
                  setselectedSub={setselectedSub}
                  selectedSub={selectedSub}
                  allSizes={allSizes}
                  selectedSize={selectedSize}
                  setselectedSize={setselectedSize}
                  allColours={allColours}
                  selectedColour={selectedColour}
                  setselectedColour={setselectedColour}
                  setminPrice={setminPrice}
                  minPrice={minPrice}
                  setmaxPrice={setmaxPrice}
                  maxPrice={maxPrice}
                  filterSortValue={filterSortValue}
                  setFilterSortValue={setFilterSortValue}
                  setCurrentPage={setCurrentPage}
                  setmainDesc={setmainDesc}
                  setsearch={setsearch}
                  search={search}
                />
              </div>
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
              <div className="flex-1 ">
                <div className="flex-1 grid grid-cols-2 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-10 ">
                  {nProducts?.length > 0 && nProducts?.map((item, index) => (
                    <ProductCardNew data2={item} key={index} />
                  ))}
                  {nProducts.length === 0 && <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-xs sm:text-sm">
                    No Products found.
                  </span>}
                </div>
                {/* PAGINATION */}
                <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                  <Pagination
                    pageLimit={pageLimit}
                    dataCount={dataCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                  <ButtonPrimary
                    // loading
                    onClick={() => {

                      setpageLimit(pageLimit + 3);
                    }}
                  >Show me more</ButtonPrimary>
                </div>
              </div>
            </div>

          </main>
        </div>

        {/* === SECTION 5 === */}
        {/* <hr className="border-slate-200 dark:border-slate-700" />

        <SectionSliderCollections /> */}
        <hr className="border-slate-200 dark:border-slate-700" />

        {/* SUBCRIBES */}

      </div>
      <SectionPromo1 />
    </div>
  );
};

export default PageCollection2;
